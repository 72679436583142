import React, { useEffect } from "react";
import "./Alerts.css";

import { useStateContext } from "../../StateContext";

export default function LoadingAlertDialog() {
  const { setIsLoading } = useStateContext();

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === "Backspace") {
        setIsLoading(false);
      }
    }

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [setIsLoading]); 

  return (
    <div className="loading-alert-container">
      <div className="loading-alert-lds-ripple">
        <div></div>
        <div></div>
      </div>
    </div>
  );
}
