import "./About.css";

export default function About() {
  return (
    <>
      <div className="web-pages-main-container about-main-container">
        {/* Hero */}
        <div className="hero-main">
          {/* Hero Image */}
          <img
            className="about-hero-img"
            src="https://www.apcey.com/images/hotel/hero/about_hero.jpg"
            alt=""
          ></img>
        </div>

        {/* Home Title Area*/}
        <div className="main-title-container">
          <div className="home-company-title-container">
            {/* Title */}
            <h1 className="rooms-title">About Us</h1>

            {/* Sub Title */}
            <div className="home-sub-title">
              Service, Sustainability, and continuous innovation
            </div>

            {/* Description */}
            <div className="home-content-text">
              Welcome to Apcey Hotel, where luxury and warmth converge to create
              an unparalleled hospitality experience. Nestled Gampaha, Ganemulla
              town, our hotel stands as a beacon of sophistication and comfort.
              Each detail, meticulously crafted, reflects our commitment to
              providing a haven for discerning travelers.
            </div>
          </div>
        </div>

        <div className="about-info-container">
          <div className="about-info-item about-bg-dark">
            <h1 className="about-info-item-title">Our Story</h1>
            <div className="about-info-item-content-container">
              <img
                src="https://www.apcey.com/images/hotel/hero/our_story.jpg"
                alt=""
                className="about-info-item-img"
              />
              <p className="about-info-item-text">
                Since its inception in 2010, Apcey Hotel has transformed into a
                distinguished landmark, guided by the visionary leadership of
                our esteemed owner, Mr. John Doe. His unwavering passion
                for hospitality and dedication to excellence serve as the
                cornerstones of our success. Under his guidance, Apcey Hotel has
                become synonymous with exceptional service and a commitment to
                exceeding guest expectations. We take pride in the journey we've
                embarked upon, and our vision continues to inspire us to
                redefine the standards of hospitality at Apcey Hotel.
              </p>
            </div>
          </div>

          <div className="about-info-item ">
            <h1 className="about-info-item-title">Management Team</h1>
            <div className="about-info-item-content-container">
              <img
                src="https://www.apcey.com/images/hotel/hero/management_team.jpg"
                alt=""
                className="about-info-item-img"
              />
              <p className="about-info-item-text">
                Guided by the expertise of our experienced and dedicated
                Business Manager, Mr. Andrew Smith, the management team at
                Apcey Hotel comprises a dynamic fusion of industry veterans and
                skilled professionals. Collectively, we bring a wealth of
                knowledge to the table, orchestrating seamless operations and
                delivering exceptional guest experiences. Our leadership sets
                the tone for our commitment to excellence, ensuring that every
                aspect of your stay reflects the highest standards of
                hospitality. Our team is dedicated to curating moments that go
                beyond expectations, making your experience truly extraordinary.
              </p>
            </div>
          </div>

          <div className="about-info-item">
            <h1 className="about-info-item-title">Commitment to Excellence</h1>
            <div className="about-info-item-content-container">
              <img
                src="https://www.apcey.com/images/hotel/hero/commitment.jpg"
                alt=""
                className="about-info-item-img"
              />
              <p className="about-info-item-text">
                Our Story Established in [Year], [Your Hotel Name] has evolved
                into a distinguished landmark under the visionary leadership of
                our esteemed owner, [Owner's Name]. Their passion for
                hospitality and commitment to excellence form the bedrock of our
                success. Management Team Led by our experienced and dedicated
                General Manager, [Manager's Name], our management team is a
                fusion of industry veterans and dynamic professionals. Together,
                we bring a wealth of expertise to ensure seamless operations and
                exceptional guest experiences. Commitment to Excellence At [Your
                Hotel Name], we believe in working collaboratively to exceed
                expectations. Our team's ethos revolves around personalized
                service, sustainability, and continuous innovation. We're not
                just a hotel; we're a community dedicated to creating memorable
                moments for our guests.
              </p>
            </div>
          </div>

          <div className="about-info-item">
            <h1 className="about-info-item-title">
              Sustainability Initiatives
            </h1>
            <div className="about-info-item-content-container">
              <img
                src="https://www.apcey.com/images/hotel/hero/sustainability.jpg"
                alt=""
                className="about-info-item-img"
              />
              <p className="about-info-item-text">
                Our commitment to sustainability is woven into the fabric of our
                operations. Recognizing the importance of minimizing our
                environmental impact, we have embraced a range of initiatives to
                foster eco-conscious practices. From implementing
                energy-efficient systems to incorporating eco-friendly
                amenities, every aspect of our hotel reflects our dedication to
                preserving the planet. We believe that luxury and responsibility
                can coexist harmoniously, offering our guests a truly indulgent
                experience while minimizing our ecological footprint. Join us in
                our journey towards a greener, more sustainable future, where
                every stay contributes to positive change.
              </p>
            </div>
          </div>

          <div className="about-info-item ">
            <h1 className="about-info-item-title">Philanthropy</h1>
            <div className="about-info-item-content-container">
              <img
                src="https://www.apcey.com/images/hotel/hero/philanthropy.jpg"
                alt=""
                className="about-info-item-img"
              />
              <p className="about-info-item-text">
                Beyond hospitality, we actively engage in philanthropic
                endeavors, supporting local communities and initiatives. We
                believe in giving back and making a positive impact on the
                places we call home. Join us on a journey that transcends the
                ordinary, where history, dedication, and innovation converge to
                define the essence of [Your Hotel Name]. Welcome to a legacy of
                hospitality that's as rich as our history
              </p>
            </div>
          </div>

          <div className="about-info-item ">
            <h1 className="about-info-item-title">Our Leadership</h1>
            <div class="about-board-of-directors-container">
              {/* Person 1 */}
              {/* <div class="about-person-item">
                <div class="about-img-item">
                  <img
                    src="https://www.apcey.com/images/people/thanuda_lakshan_kumara.jpg"
                    alt="Thanuda Lakshan Kumara"
                    className="about-director-img"
                  />
                </div>
                <h2 className="about-person-item-name">
                  Thanuda Lakshan Kumara
                </h2>
                <h3 className="about-person-item-title">| President |</h3>
                <p className="about-person-item-mesage">
                  "As the owner, I take immense pride in crafting a haven where
                  luxury seamlessly intertwines with heartfelt warmth. Ensuring
                  your comfort and satisfaction is our constant endeavor,
                  turning every moment into a cherished memory. Whether for
                  business or leisure, we feel privileged to be a part of your
                  journey. Thank you for choosing our Hotel. Your trust is our
                  inspiration to continue delivering an extraordinary
                  experience"
                </p>
              </div> */}

              {/* Person 2 */}
              {/* <div class="about-person-item">
                <div class="about-img-item">
                  <img
                    src="https://www.apcey.com/images/people/dinith_rukshan_kumara.jpg"
                    alt="Dinith Rukshan Kumara"
                    className="about-director-img"
                  />
                </div>
                <h2 className="about-person-item-name">
                  Dinith Rukshan Kumara
                </h2>
                <h3 className="about-person-item-title">
                  | Business Manager |
                </h3>
                <p className="about-person-item-mesage">
                  "As the proud manager, I extend a warm welcome. Our team is
                  dedicated to ensuring your stay is nothing short of
                  exceptional. From the moment you arrive, our commitment to
                  unparalleled service and attention to detail sets the stage
                  for a memorable experience. Whether for business or leisure,
                  we are here to cater to your every need. Thank you for
                  choosing us. We look forward to making your stay
                  extraordinary."
                </p>
              </div> */}

              {/* Person 1 New*/}
              <div class="about-person-item">
                <div class="about-img-item">
                  <img
                    src="https://www.apcey.com/images/people/person_president.jpg"
                    alt="John Doe"
                    className="about-director-img"
                  />
                </div>
                <h2 className="about-person-item-name">John Doe</h2>
                <h3 className="about-person-item-title">| President |</h3>
                <p className="about-person-item-mesage">
                  "As the owner, I take immense pride in crafting a haven where
                  luxury seamlessly intertwines with heartfelt warmth. Ensuring
                  your comfort and satisfaction is our constant endeavor,
                  turning every moment into a cherished memory. Whether for
                  business or leisure, we feel privileged to be a part of your
                  journey. Thank you for choosing our Hotel. Your trust is our
                  inspiration to continue delivering an extraordinary
                  experience"
                </p>
              </div>

              {/* Person 2 */}
              <div class="about-person-item">
                <div class="about-img-item">
                  <img
                    src="https://www.apcey.com/images/people/person_manager2.jpg"
                    alt="Andrew Smith"
                    className="about-director-img"
                  />
                </div>
                <h2 className="about-person-item-name">
                  Andrew Smith
                </h2>
                <h3 className="about-person-item-title">
                  | Business Manager |
                </h3>
                <p className="about-person-item-mesage">
                  "As the proud manager, I extend a warm welcome. Our team is
                  dedicated to ensuring your stay is nothing short of
                  exceptional. From the moment you arrive, our commitment to
                  unparalleled service and attention to detail sets the stage
                  for a memorable experience. Whether for business or leisure,
                  we are here to cater to your every need. Thank you for
                  choosing us. We look forward to making your stay
                  extraordinary."
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
