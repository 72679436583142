// CommonLayout.js
import React from 'react';
import Navbar from './Navbar/Navbar';
import Footer from './Footer/Footer';

const CommonLayout = ({ children }) => {
  return (
    <div className='common-layout-container'>
      <Navbar />
      {children}
      <Footer />
    </div>
  );
};

export default CommonLayout;
