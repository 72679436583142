// ImageGallery.js
import React, { useState, useEffect } from "react";
import "./ImageGallery.css";

const ImageGallery = ({ images, initialIndex, onClose }) => {
  const [currentIndex, setCurrentIndex] = useState(initialIndex || 0);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleKeyDown = (e) => {
    if (e.key === "Escape") {
      onClose();
    } else if (e.key === "ArrowLeft") {
      handlePrev();
    } else if (e.key === "ArrowRight") {
      handleNext();
    }
  };

  useEffect(() => {
    setCurrentIndex(initialIndex || 0);
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      // Cleanup: Remove the event listener when the component unmounts
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [initialIndex, onClose]);

  return (
    <div className="image-gallery">
      <button
        className="close-button"
        onClick={() => {
          onClose();
        }}
      >
        Close
      </button>
      <img
        src="https://www.apcey.com/icons/left_arrow.svg"
        className="arrow left-arrow"
        onClick={handlePrev}
      ></img>
      <div className="image-container">
        <img src={images[currentIndex]} alt={`Image ${currentIndex + 1}`} />
      </div>
      <img
        src="https://www.apcey.com/icons/right_arrow.svg"
        className="arrow right-arrow"
        onClick={handleNext}
      ></img>
      <div className="navigation">
        <span className="index">
          {currentIndex + 1} of {images.length}
        </span>
      </div>
    </div>
  );
};

export default ImageGallery;
