import './index.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
// import reportWebVitals from './reportWebVitals';
import App from "./App";
import './common-styles.css';

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <App></App>
  // <React.StrictMode>
  // </React.StrictMode>
);

// reportWebVitals();

