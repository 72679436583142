import "./Contact.css";
import { useState, useEffect } from "react";
import { useStateContext } from "../../StateContext";
import { postReq } from "../../api/APIClient";

export default function Contact() {
  const {
    setIsLoading,
    setSimpleAlert,
    setConfirmAlert,
    setConfirmAlertEvent,
    confirmAlertEvent,
  } = useStateContext();

  const [curTitle, setCurTitle] = useState("Mr");
  const titlesArray = ["Mr.", "Mrs.", "Ms."];
  const initialMessageModel = {
    createdTime: "",
    state: "New",
    fname: "",
    lname: "",
    phone: "",
    email: "",
    message: "",
  };
  const [messageObj, setMessageObj] = useState(initialMessageModel);

  const sendMessageRequest = async () => {
    setIsLoading(true);
    const response = await postReq("sendMessage", messageObj, false, false);
    setIsLoading(false);

    if (response.status === "success") {
      sendNotificationRequest(messageObj);
      setSimpleAlert(
        "Success",
        "Message successfully sent. \n\nWe will reply you within 6 hours\n\n(If not please contact (+94)712205076",
        "Ok",
        1
      );
      setMessageObj(initialMessageModel);
    } else {
      setSimpleAlert(
        "Fail",
        "Failed to send message. Try again or call us on (+94)712205076",
        "Ok",
        2
      );
    }
  };

  useEffect(() => {
    if (confirmAlertEvent === "sendMessage") {
      sendMessageRequest();
    }
    setConfirmAlertEvent("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmAlertEvent, setConfirmAlertEvent]);

  const sendNotificationRequest = async (messageObj) => {
    await postReq(
      "sendNotification",
      {
        title: "New Contact Message",
        message:
          "Message: " +
          messageObj.message +
          " | Name: " +
          messageObj.name +
          " | Phone: " +
          messageObj.phone,
      },
      false,
      false
    );
  };

  return (
    <>
      <div className="web-pages-main-container contact-main-container">
        {/* Hero */}
        <div className="hero-main">
          {/* Hero Image */}
          <img
            className="about-hero-img"
            src="https://www.apcey.com/images/hotel/hero/contact_us_8.png"
            alt=""
          ></img>
        </div>

        {/* Home Title Area*/}
        <div className="main-title-container">
          <div className="home-company-title-container">
            {/* Title */}
            <h1 className="rooms-title">Contact Us</h1>

            {/* Sub Title */}
            <div className="home-sub-title">
              Connect for Personalized Assistance and Inquiries
            </div>

            {/* Description */}
            <div className="home-content-text">
              Have questions or ready to book your stay? Our dedicated team at
              Apcey Hotel is here to assist you. Contact us for personalized
              assistance, inquiries, or reservations. Your comfort is our
              priority, and we look forward to welcoming you to a memorable
              experience. Reach out to us today.
            </div>
          </div>
        </div>

        {/* Get in touch */}
        <div className="contact-form-main-container">
          <h1 className="contact-get-in-touch-title">Get in touch with us</h1>

          {/* Sub Title */}
          <div className="contact-sub-title">
            Use below form for quick and easy sending of reservations,
            inquiries, clarifications or any messages. We're always here for
            you!
          </div>

          {/* Get in touch Image & Form */}
          <div className="contact-form-sub-container">
            <img
              // src="https://www.apcey.com/images/hotel/hero/get_in_touch_1.jpg"
              src="https://www.apcey.com/images/hotel/hero/get_in_touch_3.png"
              alt="Get in Touch"
              className="contact-form-img"
            />

            {/* Form */}
            <div className="contact-form-block-container">
              <h1 className="contact-form-title">Send Your Message</h1>

              <div className="contact-form-block-name-container">
                {/* Mr. Mrs */}
                <div className="contact-form-block-item">
                  {/* <h1 className="contact-form-block-input-title">Title</h1> */}
                  {/* Dropdown */}
                  <div className="dropdown-full-width">
                    <div className="dropdown-main" style={{ width: "100%" }}>
                      <button
                        className="dropdown-btn"
                        style={{
                          height: "2.2rem",
                          fontSize: "0.96rem",
                          fontWeight: "600",
                        }}
                      >
                        {curTitle}
                        <img
                          className="drop-icon filter-svg"
                          src="https://www.apcey.com/icons/chevron_down.svg"
                          alt="status"
                        />
                      </button>
                      {
                        <div className="dropdown-content">
                          {titlesArray?.map((title, index) => (
                            <div
                              key={index}
                              className="dropdown-item"
                              onClick={(event) => {
                                // var tempItem = {
                                //   ...item,
                                //   category: category?.name,
                                //   initPrice: category?.initPrice,
                                //   hourPrice: category?.hourPrice,
                                //   discount: category?.discount,
                                //   maxPerson: category?.maxPerson,
                                //   maxAdult: category?.maxAdult,
                                //   maxChild: category?.maxChild,
                                //   extraAdult: category?.extraAdult,
                                //   extraChild: category?.extraChild,
                                //   adults: 1,
                                //   childs: 0,
                                //   room: "",
                                //   roomId: "",
                                // };
                                // calcPrices(index, tempItem);
                                setCurTitle(title);
                              }}
                            >
                              {title}
                            </div>
                          ))}
                        </div>
                      }
                    </div>
                  </div>
                </div>

                <div className="contact-form-block-item">
                  {/* <h1 className="contact-form-block-input-title">First Name</h1> */}
                  <input
                    type="text"
                    className="contact-form-block-input"
                    placeholder="First Name"
                    value={messageObj.fname}
                    onChange={(e) => {
                      setMessageObj({ ...messageObj, fname: e.target.value });
                    }}
                  />
                </div>

                <div className="contact-form-block-item">
                  {/* <h1 className="contact-form-block-input-title">Last Name</h1> */}
                  <input
                    type="text"
                    className="contact-form-block-input"
                    placeholder="Last Name"
                    value={messageObj.lname}
                    onChange={(e) => {
                      setMessageObj({ ...messageObj, lname: e.target.value });
                    }}
                  />
                </div>
              </div>

              <div className="contact-form-block-email-container">
                {/* <h1 className="contact-form-block-input-title">Email</h1> */}
                <input
                  type="text"
                  className="contact-form-block-input"
                  placeholder="Your Email"
                  value={messageObj.email}
                  onChange={(e) => {
                    setMessageObj({ ...messageObj, email: e.target.value });
                  }}
                />

                <input
                  type="text"
                  className="contact-form-block-input"
                  placeholder="Phone Number"
                  value={messageObj.phone}
                  onChange={(e) => {
                    setMessageObj({ ...messageObj, phone: e.target.value });
                  }}
                />
              </div>

              <div className="contact-form-block-message-container">
                {/* <h1 className="contact-form-block-input-title">Message</h1> */}
                <textarea
                  type="text"
                  className="contact-form-block-message-text-area"
                  placeholder="Message"
                  cols="30"
                  rows="4"
                  value={messageObj.message}
                  onChange={(e) => {
                    setMessageObj({ ...messageObj, message: e.target.value });
                  }}
                />
              </div>

              <div
                className="contact-send-button-container"
                onClick={() => {
                  if (!messageObj.fname) {
                    setSimpleAlert(
                      "Warning",
                      "Ensure that first name is entered",
                      "OK",
                      4
                    );
                  } else if (!messageObj.lname) {
                    setSimpleAlert(
                      "Warning",
                      "Ensure that last name is entered",
                      "OK",
                      4
                    );
                  } else if (!messageObj.email) {
                    setSimpleAlert(
                      "Warning",
                      "Ensure that your email is entered",
                      "OK",
                      4
                    );
                  } else if (!messageObj.phone) {
                    setSimpleAlert(
                      "Warning",
                      "Ensure that your phone number is entered",
                      "OK",
                      4
                    );
                  } else if (!messageObj.message) {
                    setSimpleAlert(
                      "Warning",
                      "Ensure that your message is entered",
                      "OK",
                      4
                    );
                  } else {
                    var tempMessageObj = {
                      ...messageObj,
                      createdTime: Date.now(),
                      name:
                        curTitle +
                        " " +
                        messageObj.fname +
                        " " +
                        messageObj.lname,
                    };

                    setMessageObj(tempMessageObj);
                    setConfirmAlert(
                      "Confirm",
                      "Are you sure to send message now ?",
                      "Yes",
                      "No",
                      4,
                      "sendMessage"
                    );
                  }
                }}
              >
                <p className="contact-send-button-text">Send Message</p>
                <img
                  className="contact-send-button-img"
                  src="https://www.apcey.com/icons/right_arrow.svg"
                  alt="send message button icon"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Contact Information */}
        <div className="contact-info-container">
          <h1 className="contact-info-title">Contact Information</h1>

          <div className="contact-info-items-container">
            {/* By Phone */}
            <div className="contact-info-item">
              <img
                src="https://www.apcey.com/icons/receptionist.svg"
                alt="Phone"
                className="contact-info-item-img filter-svg"
              />
              <h2 className="contact-info-item-title">By Phone</h2>
              <p className="contact-info-item-time">
                (Monday to Friday, 9am to 6pm GMT +5:30)
              </p>
              <h3 className="contact-info-item-sub-title">Land Line</h3>
              <a href="tel:+94332261212" className="contact-info-content">
                (+94) 33 22 61 212
              </a>
              <h3 className="contact-info-item-sub-title">Mobile</h3>
              <a href="tel:+94712205076" className="contact-info-content">
                (+94) 71 22 05 076
              </a>
            </div>

            {/* Send Email */}
            <div className="contact-info-item">
              <img
                src="https://www.apcey.com/icons/email_2.svg"
                alt="Phone"
                className="contact-info-item-img filter-svg"
              />
              <h2 className="contact-info-item-title">Send Email</h2>
              <h3 className="contact-info-item-sub-title">Reservation</h3>
              <a
                href="mailto:reservation@acey.com"
                className="contact-info-content"
              >
                reservation@apcey.com
              </a>
              <h3 className="contact-info-item-sub-title">Other</h3>
              <a href="mailto:hotel@acey.com" className="contact-info-content">
                hotel@apcey.com
              </a>
            </div>

            {/* Live Chat */}
            <div className="contact-info-item">
              <img
                src="https://www.apcey.com/icons/live_chat.svg"
                alt="Phone"
                className="contact-info-item-img filter-svg"
              />
              <h2 className="contact-info-item-title">Live Chat</h2>
              <h3 className="contact-info-item-sub-title">Facebook</h3>
              <a
                href="https://www.facebook.com/apcey"
                target="_blank"
                rel="noopener noreferrer"
                className="contact-info-content"
              >
                facebook.com/apcey
              </a>
              <h3 className="contact-info-item-sub-title">WhatsApp</h3>

              <a
                href="https://wa.me/+94712205076"
                target="_blank"
                rel="noopener noreferrer"
                className="contact-info-content"
              >
                web.whatsapp.com
              </a>
            </div>
          </div>
        </div>

        <div className="contact-locate-container">
          <h1 className="contact-locate-title">Locate Us</h1>
          {/* <h2 className="contact-locate-address">
            Address: 320/B, Galahitiyawa, Ganemulla
          </h2> */}
          <h2 className="contact-locate-address">
            Discover the charm of Apcey Hotel at 320/B, Galahitiyawa, Ganemulla.
            Nestled in the heart of the Ganemulla Town. Our prime location
            awaits for you.
          </h2>

          {/* Map */}
          <div className="map-container">
            <iframe
              title="Hotel Location"
              width="100%"
              height="100%"
              className="contact-map-frame"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3959.5371166774057!2d79.95112128495515!3d7.063544698462504!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae2f951db9cff61%3A0x2a9190e35cb48b9c!2sApcey%20Technologies!5e0!3m2!1sen!2slk!4v1695025520473!5m2!1sen!2slk"
              allowFullScreen
            ></iframe>
          </div>

          {/* Open in Maps */}
          {/* <div className="contact-open-map-container"> */}
          <a
            href="https://www.google.com/maps?q=7.063543021384136,79.95599304634153"
            target="Apcey Technologies"
            rel="noopener noreferrer"
            className="open-in-map-text"
          >
            Open in Maps
          </a>
          {/* </div> */}
        </div>
      </div>
    </>
  );
}
