import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
import "./Navbar.css";
import { Link, useLocation } from "react-router-dom";
import { useStateContext } from "../../StateContext";

export default function Navbar() {
  const [isAuth, setIsAuth] = useState(false);
  // const [toggleMenu, setToggleMenu] = useState(false);
  // const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [scrollY, setScrollY] = useState(0);
  const location = useLocation();
  const [authCode, setAuthCode] = useState([]);
  const { setSimpleAlert } = useStateContext();
  // const toggleNav = () => {
  //   setToggleMenu(!toggleMenu);
  // };

  useEffect(() => {
    checkAuthData();

    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    // const changeWidth = () => {
    //   setScreenWidth(window.innerWidth);
    // };

    // window.addEventListener("resize", changeWidth);

    return () => {
      // window.removeEventListener("resize", changeWidth);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const checkAuthData = async () => {
    var lastAuth = await JSON.parse(localStorage.getItem("lastAuth"));
    // localStorage.removeItem("lastAuth");
    if (lastAuth) {
      setIsAuth(lastAuth);
    } else {
      setIsAuth(false);
    }
  };

  return (
    <div>
      <nav className={scrollY > 10 ? "nav-bg-dark" : "nav-bg-normal"}>
        <div className="nav-menu-items-container">
          <Link to="/" className="nav-link">
            <p className={"nav-link-p"}>Home</p>
            <span
              className={
                location.pathname === "/"
                  ? "nav-menu-bottom-indicator"
                  : "border-none"
              }
            ></span>
          </Link>

          <Link to="/rooms" className="nav-link">
            <p className={"nav-link-p"}>Rooms</p>
            <span
              className={
                location.pathname === "/rooms"
                  ? "nav-menu-bottom-indicator"
                  : "border-none"
              }
            ></span>
          </Link>

          <Link to="/gallery" className="nav-link">
            <p className={"nav-link-p"}>Gallery</p>
            <span
              className={
                location.pathname === "/gallery"
                  ? "nav-menu-bottom-indicator"
                  : "border-none"
              }
            ></span>
          </Link>

          <img
            className="nav-company-logo"
            src="https://www.apcey.com/icons/b.svg"
            alt="logo"
          ></img>

          <Link to="/faq" className="nav-link">
            <p className={"nav-link-p"}>Faq</p>
            <span
              className={
                location.pathname === "/faq"
                  ? "nav-menu-bottom-indicator"
                  : "border-none"
              }
            ></span>
          </Link>

          <Link to="/about" className="nav-link">
            <p className={"nav-link-p"}>About</p>
            <span
              className={
                location.pathname === "/about"
                  ? "nav-menu-bottom-indicator"
                  : "border-none"
              }
            ></span>
          </Link>

          <Link to="/contact" className="nav-link">
            <p className={"nav-link-p"}>Contact</p>
            <span
              className={
                location.pathname === "/contact"
                  ? "nav-menu-bottom-indicator"
                  : "border-none"
              }
            ></span>
          </Link>
        </div>
      </nav>

      {!isAuth && (
        <div className="fixed-alert-container">
          <div className="auth-inside-container">
            <div className="book-bar-name-item">
              <p className="auth-title">Authorization Code</p>
              <input
                className="book-bar-name-input margin-top-0-25 font-size-0-9"
                value={authCode}
                onChange={(event) => {
                  setAuthCode(event.target.value);
                }}
              />
            </div>
            <button
              className="book-btn auth-btn-2"
              onClick={() => {
                if (authCode === "DRK" || authCode === "TLK" || authCode === "DDD" || authCode === "TTT") {
                  localStorage.setItem("lastAuth", Date.now());
                  setIsAuth(true);
                } else {
                  setSimpleAlert(
                    "Warning",
                    "Auth code is wrong. Try again or contact system administrator.",
                    "OK",
                    4
                  );
                }
              }}
            >
              Authorize
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
