import "react-datepicker/dist/react-datepicker.css";
import "./Gallery.css";
import ImageGallery from "../ImageGallery";
import { useState, useEffect, useRef } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";

export default function Gallery() {
  let navigate = useNavigate();
  let location = useLocation();

  const restaurantImages = [
    "https://www.apcey.com/images/hotel/restaurant/restaurant_1.jpg",
    "https://www.apcey.com/images/hotel/restaurant/restaurant_2.jpg",
    "https://www.apcey.com/images/hotel/restaurant/restaurant_3.jpg",
    "https://www.apcey.com/images/hotel/restaurant/restaurant_4.jpg",
    "https://www.apcey.com/images/hotel/restaurant/restaurant_5.jpg",
    "https://www.apcey.com/images/hotel/restaurant/restaurant_6.jpg",
  ];

  const barImages = [
    "https://www.apcey.com/images/hotel/bar/bar_1.jpg",
    "https://www.apcey.com/images/hotel/bar/bar_2.jpg",
    "https://www.apcey.com/images/hotel/bar/bar_3.jpg",
    "https://www.apcey.com/images/hotel/bar/bar_4.jpg",
    "https://www.apcey.com/images/hotel/bar/bar_5.jpg",
    "https://www.apcey.com/images/hotel/bar/bar_6.jpg",
  ];

  const poolImages = [
    "https://www.apcey.com/images/hotel/pool/pool_1.jpg",
    "https://www.apcey.com/images/hotel/pool/pool_2.jpg",
    "https://www.apcey.com/images/hotel/pool/pool_3.jpg",
    "https://www.apcey.com/images/hotel/pool/pool_4.jpg",
    "https://www.apcey.com/images/hotel/pool/pool_5.jpg",
    "https://www.apcey.com/images/hotel/pool/pool_6.jpg",
  ];

  const gardenImages = [
    "https://www.apcey.com/images/hotel/garden/garden_1.jpg",
    "https://www.apcey.com/images/hotel/garden/garden_2.jpg",
    "https://www.apcey.com/images/hotel/garden/garden_3.jpg",
    "https://www.apcey.com/images/hotel/garden/garden_4.jpg",
    "https://www.apcey.com/images/hotel/garden/garden_5.jpg",
    "https://www.apcey.com/images/hotel/garden/garden_6.jpg",
  ];

  const banquetImages = [
    "https://www.apcey.com/images/hotel/banquet/banquet_1.jpg",
    "https://www.apcey.com/images/hotel/banquet/banquet_2.jpg",
    "https://www.apcey.com/images/hotel/banquet/banquet_3.jpg",
    "https://www.apcey.com/images/hotel/banquet/banquet_4.jpg",
    "https://www.apcey.com/images/hotel/banquet/banquet_5.jpg",
    "https://www.apcey.com/images/hotel/banquet/banquet_6.jpg",
  ];

  const [currentImageSet, setCurrentImageSet] = useState([]);
  const [galleryOpen, setGalleryOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const openGallery = () => {
    setGalleryOpen(true);
  };

  const closeGallery = () => {
    setGalleryOpen(false);
    setCurrentImageSet([]);
  };

  const setGallery = (newIndex, imagesArray) => {
    setCurrentImageIndex(newIndex);
    setCurrentImageSet(imagesArray);
  };

  useEffect(() => {
    if (currentImageSet.length > 0) {
      openGallery();
    }
  }, [currentImageSet]);

  return (
    <>
      {/* Image Gallery */}
      {galleryOpen && (
        <div className="gallery-container">
          <ImageGallery
            images={currentImageSet}
            initialIndex={currentImageIndex}
            onClose={closeGallery}
          />
        </div>
      )}

      <div className="web-pages-main-container">
        {/* Hero & Booking bar Area */}
        <div className="hero-main">
          {/* Hero Image */}
          <img
            className="about-hero-img"
            src="https://www.apcey.com/images/hotel/pool/pool_1.jpg"
            alt=""
          ></img>
        </div>

        {/* Gallery Title Area*/}
        <div className="main-title-container">
          <div className="home-company-title-container">
            {/* Title */}
            {/* <div className="home-title-text">Apcey Gallery</div> */}
            <h1 className="rooms-title">Apcey Gallery</h1>

            {/* Sub Title */}
            <div className="home-sub-title">
              Captivating Moments, Explore Our Stunning Gallery
            </div>

            {/* Description */}
            <div className="home-content-text">
              Dive into a visual journey through our hotel gallery, where every
              image tells a story of luxury and comfort. Immerse yourself in the
              captivating allure of our meticulously designed rooms, exquisite
              dining experiences, and enchanting surroundings. From elegant
              interiors to breathtaking views, our gallery is a testament to the
              unforgettable moments that await you. Discover the essence of
              indulgence and hospitality in every frame.
            </div>
          </div>
          {/* <img className="home-title-bg-img-left" src={treeLeafIcon} alt="" /> */}
          {/* <img className="home-title-bg-img-right" src="https://www.apcey.com/icons/tree_leaf.png" alt="" /> */}
        </div>

        <div className="gallery-main-container">
          <div className="gallery-item-container">
            <div className="gallery-item-title">Restaurant</div>
            <div className="gallery-images-container">
              {restaurantImages?.map((item, index) => (
                <div
                  className="rooms-img-item-container"
                  onClick={() => {
                    setGallery(index, restaurantImages);
                  }}
                >
                  <img src={item} alt="" className="gallery-img" />
                  <img src="https://www.apcey.com/icons/magnify.svg" alt="" className="gallery-magnify-icon" />
                </div>
              ))}
            </div>
          </div>

          <div className="gallery-item-container">
            <div className="gallery-item-title">Bars</div>
            <div className="gallery-images-container">
              {barImages?.map((item, index) => (
                <div
                  className="rooms-img-item-container"
                  onClick={() => {
                    setGallery(index, barImages);
                  }}
                >
                  <img src={item} alt="" className="gallery-img" />
                  <img src="https://www.apcey.com/icons/magnify.svg" alt="" className="gallery-magnify-icon" />
                </div>
              ))}
            </div>
          </div>

          <div className="gallery-item-container">
            <div className="gallery-item-title">Swimming Pools</div>
            <div className="gallery-images-container">
              {poolImages?.map((item, index) => (
               <div
               className="rooms-img-item-container"
               onClick={() => {
                 setGallery(index, poolImages);
               }}
             >
               <img src={item} alt="" className="gallery-img" />
               <img src="https://www.apcey.com/icons/magnify.svg" alt="" className="gallery-magnify-icon" />
             </div>
              ))}
            </div>
          </div>

          <div className="gallery-item-container">
            <div className="gallery-item-title">Gardens</div>
            <div className="gallery-images-container">
              {gardenImages?.map((item, index) => (
               <div
               className="rooms-img-item-container"
               onClick={() => {
                 setGallery(index, gardenImages);
               }}
             >
               <img src={item} alt="" className="gallery-img" />
               <img src="https://www.apcey.com/icons/magnify.svg" alt="" className="gallery-magnify-icon" />
             </div>
              ))}
            </div>
          </div>

          <div className="gallery-item-container">
            <div className="gallery-item-title">Banquets</div>
            <div className="gallery-images-container">
              {banquetImages?.map((item, index) => (
               <div
               className="rooms-img-item-container"
               onClick={() => {
                 setGallery(index, banquetImages);
               }}
             >
               <img src={item} alt="" className="gallery-img" />
               <img src="https://www.apcey.com/icons/magnify.svg" alt="" className="gallery-magnify-icon" />
             </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
