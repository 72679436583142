import "./Home.css";
import "react-datepicker/dist/react-datepicker.css";
import { useStateContext } from "../../StateContext";
import { useState, useEffect } from "react";
// import { useNavigate, useLocation } from "react-router-dom";

import DatePicker from "react-datepicker";
import ImageGallery from "../ImageGallery";
import { postReq } from "../../api/APIClient";

export default function Home() {
  // let navigate = useNavigate();
  // let location = useLocation();
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  const tomorrowDate = new Date(currentDate);
  tomorrowDate.setDate(currentDate.getDate() + 1);
  tomorrowDate.setHours(0, 0, 0, 0);

  const defaultRoomText = "0 Rooms, 0 Adults, 0 Kids";
  const [startTime, setStartTime] = useState(Math.floor(currentDate));
  const [endTime, setEndTime] = useState(Math.floor(tomorrowDate));

  const [isSquareVisible, setIsSquareVisible] = useState(false);
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [total, setTotal] = useState("0.00");
  const titlesArray = ["Mr.", "Mrs.", "Ms."];
  const [curTitle, setCurTitle] = useState("Mr.");

  // Books
  const initialBookModel = {
    startTime: Math.floor(currentDate),
    endTime: Math.floor(tomorrowDate),
    createdTIme: "",
    state: "Booked",
    person: "",
    phone: "",
    nic: "",
    email: "",
    createdBy: "",
    category: "",
    roomId: "",
    room: "",
    maxPerson: "",
    maxAdult: "",
    maxChild: "",
    adults: 1,
    childs: 0,
    initPrice: "",
    hourPrice: "",
    discount: "",
    extraAdult: "",
    extraChild: "",
    additional: 0,
    discountAmount: 0,
    total: "",
    note: "",
    remainingPercentage: 0,
  };

  const [bookingArray, setBookingArray] = useState([initialBookModel]);

  const [categories, setCategories] = useState();

  const [bookSendObj, setBookSendObj] = useState([]);

  const [roomText, setRoomText] = useState(defaultRoomText);

  const {
    setIsLoading,
    setSimpleAlert,
    setConfirmAlert,
    setConfirmAlertEvent,
    confirmAlertEvent,
  } = useStateContext();

  useEffect(() => {
    checkCategoriesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (confirmAlertEvent === "addBook") {
      addBookRequest();
    }
    setConfirmAlertEvent("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmAlertEvent, setConfirmAlertEvent]);

  const addBookRequest = async () => {
    console.log("bookSendObj", bookSendObj);
    setIsLoading(true);
    const response = await postReq("addBook", bookSendObj, false, false);
    setIsLoading(false);

    if (response.status === "success") {
      sendNotificationRequest(bookSendObj[0]);
      setSimpleAlert(
        "Success",
        "Booked " +
          bookingArray.length +
          " room for " +
          total +
          " LKR. \n\nWe will contact you within 6 hours\n(If not please contact us on 0712205076)",
        "Continue",
        1
      );
      setBookingArray([initialBookModel]);
      setStartTime(Math.floor(currentDate));
      setEndTime(Math.floor(tomorrowDate));
      setRoomText(defaultRoomText);
      setTotal("0.00");
      setIsSquareVisible(false);
    } else {
      setSimpleAlert(
        "Fail",
        "Failed to book rooms. Try again or call us on 0712205076",
        "Continue",
        2
      );
    }
  };

  const sendNotificationRequest = async (bookSendObj) => {
    // setIsLoading(true);
    await postReq(
      "sendNotification",
      {
        title: "New Booking",
        message:
          "Rs. " +
          bookSendObj.total +
          ", Category: " +
          bookSendObj.category +
          ", Name: " +
          bookSendObj.person,
      },
      false,
      false
    );
    // setIsLoading(false);

    // if (response.status === "success") {
    //   console.log("Notification Success");
    // } else {
    //   console.log("Notification failed");
    // }
  };

  const checkCategoriesData = async () => {
    var lastSync = await JSON.parse(localStorage.getItem("lastSynced"));
    var localData = await JSON.parse(
      localStorage.getItem("roomCategoriesData")
    );
    if (localData) {
      setCategories(localData);
    }
    if (!localData || lastSync + 800000000 < Date.now()) {
      getNewCategoriesData();
    }
  };

  const getNewCategoriesData = async () => {
    setIsLoading(true);
    const response = await postReq(
      "roomCategoriesData",
      { recordCount: 0 },
      true,
      true
    );
    setIsLoading(false);

    if (response.status === "success") {
      var newCategories = response?.records;
      setCategories(newCategories);
      localStorage.setItem("lastSynced", Date.now());
    }
  };

  function calcPrices(index, tempBook) {
    console.log("bookingArray Start", bookingArray);
    var tempBookArray = [...bookingArray];
    if (tempBook) {
      var tempAdults = parseFloat(tempBook.adults || 0);
      var tempChilds = parseFloat(tempBook.childs || 0);
      // var tempMaxPerson = parseFloat(tempBook.maxPerson || 0);
      // var tempMaxAdult = parseFloat(tempBook.maxAdult || 0);
      // var tempMaxChild = parseFloat(tempBook.maxChild || 0);
      var tempExtraAdult = parseFloat(tempBook.extraAdult || 0);
      var tempExtraChild = parseFloat(tempBook.extraChild || 0);
      // var tempHourPrice = parseFloat(tempBook.hourPrice || 0);
      var tempInitPrice = parseFloat(tempBook.initPrice || 0);
      var tempAdditional = parseFloat(tempBook.additional || 0);
      var tempDiscount = parseFloat(tempBook.discount || 0);
      // var tempTotal = parseFloat(tempBook.total || 0);

      var durationInDays = Math.floor(
        (tempBook.endTime - tempBook.startTime) / 86400000
      );

      var tempTotal =
        (tempInitPrice +
          (tempAdults > 1
            ? (tempInitPrice * tempExtraAdult * (tempAdults - 1)) / 100
            : 0) +
          (tempChilds > 0
            ? (tempInitPrice * tempExtraChild * tempChilds) / 100
            : 0)) *
        durationInDays;

      var tempDiscountAmount = (tempTotal * tempDiscount) / 100;

      var tempSubTotal = tempTotal - tempDiscountAmount + tempAdditional;

      // console.log("tempBook: ", tempBook);

      var tempBookObj = {
        ...tempBook,
        total: tempSubTotal,
        discountAmount: tempDiscountAmount,
        duration: durationInDays,
      };

      tempBookArray[index] = tempBookObj;
    } else {
      tempBookArray = [
        ...tempBookArray.slice(0, index),
        ...tempBookArray.slice(index + 1),
      ];
    }

    tempAdults = 0;
    tempChilds = 0;
    var tempFinalAmount = 0;

    for (let i = 0; i < tempBookArray.length; i++) {
      var element = tempBookArray[i];
      if (element.category) {
        tempAdults += parseInt(element.adults);
        tempChilds += parseInt(element.childs);
        tempFinalAmount += parseInt(element.total);
      }
    }

    var tempRoomText = " Room, ";
    var tempAdultText = " Adult, ";
    var tempChildText = " Kid, ";

    if (tempBookArray.length > 1) {
      tempRoomText = " Rooms, ";
    }
    if (tempAdults > 1) {
      tempAdultText = " Adults, ";
    }
    if (tempChilds > 1) {
      tempChildText = " Kids, ";
    }
    setTotal(tempFinalAmount.toFixed(2));
    setRoomText(
      tempBookArray.length +
        tempRoomText +
        tempAdults +
        tempAdultText +
        tempChilds +
        tempChildText +
        tempFinalAmount.toFixed(2) +
        "(LKR)"
    );
    console.log("bookingArray end", tempBookArray);

    setBookingArray(tempBookArray);
    // console.log("calculatePrices Running..");
  }

  const [currentImageSet, setCurrentImageSet] = useState([]);
  const [galleryOpen, setGalleryOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const openGallery = () => {
    setGalleryOpen(true);
  };

  const closeGallery = () => {
    setGalleryOpen(false);
    setCurrentImageSet([]);
  };

  const setGallery = (newIndex, imagesArray) => {
    setCurrentImageIndex(newIndex);
    setCurrentImageSet(imagesArray);
  };

  useEffect(() => {
    if (currentImageSet.length > 0) {
      openGallery();
    }
  }, [currentImageSet]);

  return (
    <>
      {/* Image Gallery */}
      {galleryOpen && (
        <div className="gallery-container">
          <ImageGallery
            images={currentImageSet}
            initialIndex={currentImageIndex}
            onClose={closeGallery}
          />
        </div>
      )}

      {isSquareVisible && (
        <div className="book-bar-popup-main-container">
          <div className="book-bar-popup-ui-container">
            <img
              className="bookbar-close-img"
              src="https://www.apcey.com/icons/cancel_round_black.svg"
              alt=""
              onClick={() => {
                setIsSquareVisible(false);
              }}
            />

            <h1 className="bookbar-title">Reservation</h1>

            <h2 className="bookbar-booking-sub-title">Fill Your Information</h2>

            <div className="book-bar-name-container">
              <div className="book-bar-name-item">
                <p className="book-bar-name-lable">Title</p>
                <div className="dropdown-full-width">
                  <div className="dropdown-main" style={{ width: "100%" }}>
                    <button
                      className="dropdown-btn"
                      style={{
                        height: "2.05rem",
                        fontSize: "0.96rem",
                        fontWeight: "600",
                      }}
                    >
                      {curTitle}
                      <img
                        className="drop-icon filter-svg"
                        src="https://www.apcey.com/icons/chevron_down.svg"
                        alt="status"
                      />
                    </button>
                    {
                      <div className="dropdown-content">
                        {titlesArray?.map((title, index) => (
                          <div
                            key={index}
                            className="dropdown-item"
                            onClick={(event) => {
                              // var tempItem = {
                              //   ...item,
                              //   category: category?.name,
                              //   initPrice: category?.initPrice,
                              //   hourPrice: category?.hourPrice,
                              //   discount: category?.discount,
                              //   maxPerson: category?.maxPerson,
                              //   maxAdult: category?.maxAdult,
                              //   maxChild: category?.maxChild,
                              //   extraAdult: category?.extraAdult,
                              //   extraChild: category?.extraChild,
                              //   adults: 1,
                              //   childs: 0,
                              //   room: "",
                              //   roomId: "",
                              // };
                              // calcPrices(index, tempItem);
                              setCurTitle(title);
                            }}
                          >
                            {title}
                          </div>
                        ))}
                      </div>
                    }
                  </div>
                </div>
              </div>

              <div className="book-bar-name-item">
                <p className="book-bar-name-lable">First Name</p>

                <input
                  className="book-bar-name-input"
                  placeholder="John"
                  value={fName}
                  onChange={(event) => {
                    setFName(event.target.value);
                  }}
                />
              </div>

              <div className="book-bar-name-item">
                <p className="book-bar-name-lable">Last Name</p>

                <input
                  className="book-bar-name-input"
                  placeholder="Doe"
                  value={lName}
                  onChange={(event) => {
                    setLName(event.target.value);
                  }}
                />
              </div>
            </div>

            <div className="book-bar-email-phone-container">
              <div className="book-bar-name-item">
                <p className="book-bar-name-lable">Phone Number</p>

                <input
                  className="book-bar-name-input"
                  placeholder="+94700000000"
                  value={phone}
                  onChange={(event) => {
                    setPhone(event.target.value);
                  }}
                />
              </div>

              <div className="book-bar-name-item">
                <p className="book-bar-name-lable">Email Address (Optional)</p>

                <input
                  className="book-bar-name-input"
                  placeholder="example@example.com"
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                />
              </div>
            </div>

            <h2 className="bookbar-booking-sub-title">
              Select Reservation Dates
            </h2>

            <div className="book-bar-date-container">
              <div className="book-bar-name-item">
                <p className="book-bar-name-lable">Check-In</p>

                <div className="book-bar-date-picker-main-container">
                  <div className="date-picker-container">
                    <DatePicker
                      showIcon
                      showTimeSelect={false}
                      dateFormat="MMMM d, yyyy"
                      selected={new Date(startTime)}
                      onChange={(date) => {
                        var tempStartDate = Math.floor(
                          date.setHours(0, 0, 0, 0)
                        );
                        if (currentDate > tempStartDate) {
                          setSimpleAlert(
                            "Warning",
                            "Please ensure that the arrival time is a future date",
                            "OK",
                            4
                          );
                        } else {
                          setStartTime(tempStartDate);
                        }
                      }}
                      className="date-picker-item"
                    />
                  </div>
                </div>
              </div>

              <div className="book-bar-name-item">
                <p className="book-bar-name-lable">Check-Out</p>
                <div className="book-bar-date-picker-main-container">
                  <div className="date-picker-container">
                    <DatePicker
                      showIcon
                      showTimeSelect={false}
                      dateFormat="MMMM d, yyyy"
                      selected={new Date(endTime)}
                      onChange={(date) => {
                        var tempEndDate = Math.floor(date.setHours(0, 0, 0, 0));
                        if (tempEndDate <= parseInt(startTime)) {
                          setSimpleAlert(
                            "Warning",
                            "Ensure that the departure time follows the arrival time",
                            "OK",
                            4
                          );
                        } else {
                          setEndTime(tempEndDate);
                          // setCurrentBook({ ...currentBook, endTime: tempEndDate });
                        }
                      }}
                      className="date-picker-item"
                    />
                  </div>
                </div>
              </div>
            </div>

            <h2 className="bookbar-booking-sub-title">Select Rooms</h2>

            {/* Room Table  */}
            <div className="book-bar-popup-head book-bar-popup-columns">
              <p className="book-bar-popup-head-text">#</p>
              <p className="book-bar-popup-head-text border-left">Category</p>
              <p className="book-bar-popup-head-text border-left">Adults</p>
              <p className="book-bar-popup-head-text border-left">Kids</p>
              <p className="book-bar-popup-head-text border-left">
                Price (Rs.)
              </p>
              <p className="book-bar-popup-head-text"></p>
            </div>

            {/* <div className="book-bar-rooms-list-main-container"> */}
            {bookingArray?.map((item, index) => (
              <div className="book-bar-room-list-container book-bar-popup-columns">
                <p className="book-bar-popup-head-text">{index + 1}</p>
                {/* Category Select Dropdown */}
                <div className="dropdown-full-width">
                  <div className="dropdown-main">
                    <button className="dropdown-btn dropdown-room-btn">
                      {item?.category}
                      <img
                        className="drop-icon filter-svg"
                        src="https://www.apcey.com/icons/chevron_down.svg"
                        alt="status"
                      />
                    </button>
                    {
                      <div className="dropdown-content">
                        {categories?.map((category, index2) => (
                          <div
                            key={index2}
                            className="dropdown-item"
                            onClick={(event) => {
                              var tempItem = {
                                ...item,
                                category: category?.name,
                                initPrice: category?.initPrice,
                                hourPrice: category?.hourPrice,
                                discount: category?.discount,
                                maxPerson: category?.maxPerson,
                                maxAdult: category?.maxAdult,
                                maxChild: category?.maxChild,
                                extraAdult: category?.extraAdult,
                                extraChild: category?.extraChild,
                                adults: 1,
                                childs: 0,
                                room: "",
                                roomId: "",
                              };
                              calcPrices(index, tempItem);
                            }}
                          >
                            {category?.name}
                          </div>
                        ))}
                      </div>
                    }
                  </div>
                </div>

                <div className="book-bar-popup-input-container">
                  <img
                    src={"https://www.apcey.com/icons/plus.svg"}
                    alt="+"
                    className="book-bar-popup-input-img"
                    onClick={() => {
                      if (
                        parseInt(item.maxPerson) <=
                        parseInt(item.adults) + parseInt(item.childs)
                      ) {
                        setSimpleAlert(
                          "Alert",
                          item.category +
                            " max people allowed is " +
                            item.maxPerson,
                          "OK",
                          4
                        );
                      } else if (!item.category) {
                        setSimpleAlert(
                          "Alert",
                          "Select Room Category first",
                          "OK",
                          4
                        );
                      } else {
                        if (parseInt(item.maxAdult) <= parseInt(item.adults)) {
                          setSimpleAlert(
                            "Alert",
                            item.category +
                              " max adults allowed is " +
                              item.maxAdult,
                            "OK",
                            4
                          );
                        } else {
                          calcPrices(index, {
                            ...item,
                            adults: item.adults + 1,
                          });
                        }
                      }
                    }}
                  />
                  <input
                    className="book-bar-popup-list-input"
                    type="text"
                    value={item.adults}
                    readOnly
                  />
                  <img
                    src="https://www.apcey.com/icons/minus.svg"
                    alt="-"
                    className="book-bar-popup-input-img"
                    onClick={() => {
                      if (item.adults > 1) {
                        calcPrices(index, {
                          ...item,
                          adults: item.adults - 1,
                        });
                      }
                    }}
                  />
                </div>

                <div className="book-bar-popup-input-container">
                  <img
                    src="https://www.apcey.com/icons/plus.svg"
                    alt="+"
                    className="book-bar-popup-input-img"
                    onClick={() => {
                      if (
                        parseInt(item.maxPerson) <=
                        parseInt(item.adults) + parseInt(item.childs)
                      ) {
                        setSimpleAlert(
                          "Alert",
                          item.category +
                            " max people allowed is " +
                            item.maxPerson,
                          "OK",
                          4
                        );
                      } else if (!item.category) {
                        setSimpleAlert(
                          "Alert",
                          "Select Room Category first",
                          "OK",
                          4
                        );
                      } else {
                        if (parseInt(item.maxChild) <= parseInt(item.childs)) {
                          setSimpleAlert(
                            "Alert",
                            item.category +
                              " max kids allowed is " +
                              item.maxChild +
                              "OK",
                            4
                          );
                        } else {
                          calcPrices(index, {
                            ...item,
                            childs: item.childs + 1,
                          });
                        }
                      }
                    }}
                  />
                  <input
                    className="book-bar-popup-list-input"
                    type="text"
                    value={item.childs}
                    readOnly
                  />
                  <img
                    src="https://www.apcey.com/icons/minus.svg"
                    alt="-"
                    className="book-bar-popup-input-img"
                    onClick={() => {
                      if (item.childs > 0) {
                        calcPrices(index, {
                          ...item,
                          childs: item.childs - 1,
                        });
                      }
                    }}
                  />
                </div>

                <p className="book-bar-popup-head-text">{item?.total}</p>

                <img
                  src="https://www.apcey.com/icons/cancel_round_black.svg"
                  alt="D"
                  className="book-bar-popup-input-img"
                  onClick={() => {
                    calcPrices(index, "");
                  }}
                />
              </div>
            ))}

            <button
              className="book-bar-popup-add-btn"
              onClick={() => {
                if (bookingArray.length > 4) {
                  setSimpleAlert(
                    "Alert",
                    "Maximum rooms allowed for online reservation is 5. If you want to book more rooms, Please contact us on 0712205076.",
                    "OK",
                    4
                  );
                } else {
                  var tempBookObj = [...bookingArray];
                  tempBookObj.push(initialBookModel);
                  setBookingArray(tempBookObj);
                }
              }}
            >
              <img
                src="https://www.apcey.com/icons/plus.svg"
                alt="Add"
                className="book-bar-popup-input-img"
              />
              Add Room
            </button>

            <button
              className="book-btn book-btn-2"
              onClick={() => {
                var isAllCategoriesSelected = true;

                for (let i = 0; i < bookingArray.length; i++) {
                  var element = bookingArray[i];
                  if (!element.category) {
                    isAllCategoriesSelected = false;
                    break;
                  }
                }

                if (endTime <= parseInt(startTime)) {
                  setSimpleAlert(
                    "Warning",
                    "Ensure that the departure time follows the arrival time",
                    "OK",
                    4
                  );
                } else if (!fName) {
                  setSimpleAlert(
                    "Warning",
                    "Ensure that your First Name is entered",
                    "OK",
                    4
                  );
                } else if (!lName) {
                  setSimpleAlert(
                    "Warning",
                    "Ensure that your Last Name is entered",
                    "OK",
                    4
                  );
                } else if (!phone) {
                  setSimpleAlert(
                    "Warning",
                    "Ensure that your phone number is entered",
                    "OK",
                    4
                  );
                } else if (bookingArray.length < 1) {
                  setSimpleAlert(
                    "Warning",
                    "Ensure that atleast 1 room is selected",
                    "OK",
                    4
                  );
                } else if (!isAllCategoriesSelected) {
                  setSimpleAlert(
                    "Warning",
                    "Ensure that room categories are selected",
                    "OK",
                    4
                  );
                } else {
                  var duration = Math.floor((endTime - startTime) / 86400000);
                  var createdTIme = Date.now();
                  var tempBookingArray = [...bookingArray];
                  for (let i = 0; i < tempBookingArray.length; i++) {
                    element = tempBookingArray[i];
                    tempBookingArray[i] = {
                      ...element,
                      startTime: startTime,
                      endTime: endTime,
                      duration: duration,
                      createdTime: createdTIme,
                      state: "Pending",
                      createdBy: "Web",
                      person: curTitle + " " + fName + " " + lName,
                      phone: phone,
                      email: email,
                      nic: "",
                      roomId: "",
                      room: "",
                      note: "",
                      // additional: "",
                      // discountAmount: "",
                    };
                  }
                  setBookSendObj(tempBookingArray);
                  setConfirmAlert(
                    "Confirm",
                    "Are you sure to booking now ?",
                    "Yes",
                    "No",
                    4,
                    "addBook"
                  );
                  // console.log("final Obj: ", tempBookingArray);
                }
              }}
            >
              Submit Now
            </button>

            <p className="book-bar-total-text">Total : {total} LKR</p>
          </div>
        </div>
      )}

      <div className="web-pages-main-container">
        {/* Hero & Booking bar Area */}
        <div className="hero-main">
          {/* Hero Image */}
          <img
            className="hero-img"
            src="https://www.apcey.com/images/hotel/hero/gallery_hero.jpg"
            alt=""
            onClick={() => {
              setIsSquareVisible(false);
            }}
          ></img>

          {/* Booking Bar */}
          <div className="book-bar-container">
            {/* Arrival Date */}
            <div
              className="book-bar-item"
              onClick={() => {
                setIsSquareVisible(false);
              }}
            >
              <p className="book-bar-item-lable">Check-In :</p>
              <div className="date-picker-container ">
                <DatePicker
                  className="date-picker-item no-border"
                  showIcon
                  showTimeSelect={false}
                  dateFormat="MMMM d, yyyy"
                  selected={new Date(startTime)}
                  onChange={(date) => {
                    var tempStartDate = Math.floor(date.setHours(0, 0, 0, 0));
                    if (currentDate > tempStartDate) {
                      setSimpleAlert(
                        "Warning",
                        "Please ensure that the arrival time is a future date",
                        "OK",
                        4
                      );
                    } else {
                      setStartTime(tempStartDate);
                    }
                  }}
                  onKeyDown={(e) => e.preventDefault()}
                />
              </div>
            </div>

            {/* Depature Date */}
            <div
              className="book-bar-item"
              onClick={() => {
                setIsSquareVisible(false);
              }}
            >
              <p className="book-bar-item-lable">Check-Out :</p>
              <div className="date-picker-container">
                <DatePicker
                  className="date-picker-item  no-border"
                  showIcon
                  showTimeSelect={false}
                  dateFormat="MMMM d, yyyy"
                  selected={new Date(endTime)}
                  onChange={(date) => {
                    var tempEndDate = Math.floor(date.setHours(0, 0, 0, 0));
                    if (tempEndDate <= parseInt(startTime)) {
                      setSimpleAlert(
                        "Warning",
                        "Ensure that the departure time follows the arrival time",
                        "OK",
                        4
                      );
                    } else {
                      setEndTime(tempEndDate);
                      // setCurrentBook({ ...currentBook, endTime: tempEndDate });
                    }
                  }}
                  onKeyDown={(e) => e.preventDefault()}
                />
              </div>
            </div>

            {/* Room Selection */}
            <div className="book-bar-room-container">
              <img
                src="https://www.apcey.com/icons/select_square_dashed.svg"
                alt="room"
                className="book-bar-icon"
                onClick={() => {
                  setIsSquareVisible(!isSquareVisible);
                }}
              />
              <input
                className="book-bar-room-input"
                placeholder={roomText}
                value={roomText}
                readOnly
                onClick={() => {
                  setIsSquareVisible(!isSquareVisible);
                }}
              />

              {/* {isSquareVisible && (
                <div className="book-bar-popup-ui-container">
                  <div className="book-bar-info-container">
                    <div className="book-bar-info-item">
                      <p className="book-bar-info-lable">Name :</p>
                      <input
                        className="book-bar-info-input"
                        placeholder="John Doe"
                        value={name}
                        onChange={(event) => {
                          setName(event.target.value);
                        }}
                      />
                    </div>

                    <div className="book-bar-info-item">
                      <p className="book-bar-info-lable">Phone :</p>
                      <input
                        className="book-bar-info-input"
                        placeholder="0700000000"
                        value={phone}
                        onChange={(event) => {
                          setPhone(event.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="book-bar-popup-head book-bar-popup-columns">
                    <p className="book-bar-popup-head-text">#</p>
                    <p className="book-bar-popup-head-text border-left">
                      Category
                    </p>
                    <p className="book-bar-popup-head-text border-left">
                      Adults
                    </p>
                    <p className="book-bar-popup-head-text border-left">Kids</p>
                    <p className="book-bar-popup-head-text border-left">
                      Price (Rs.)
                    </p>
                    <p className="book-bar-popup-head-text"></p>
                  </div>

                  {bookingArray?.map((item, index) => (
                    <div className="book-bar-room-list-container book-bar-popup-columns">
                      <p className="book-bar-popup-head-text">{index + 1}</p>

                      <div className="dropdown-full-width">
                        <div className="dropdown-main">
                          <button className="dropdown-btn">
                            {item?.category}
                            <img
                              className="drop-icon filter-svg"
                              src="https://www.apcey.com/icons/chevron_down.svg"
                              alt="status"
                            />
                          </button>
                          {
                            <div className="dropdown-content">
                              {categories?.map((category, index2) => (
                                <div
                                  key={index2}
                                  className="dropdown-item"
                                  onClick={(event) => {
                                    var tempItem = {
                                      ...item,
                                      category: category?.name,
                                      initPrice: category?.initPrice,
                                      hourPrice: category?.hourPrice,
                                      discount: category?.discount,
                                      maxPerson: category?.maxPerson,
                                      maxAdult: category?.maxAdult,
                                      maxChild: category?.maxChild,
                                      extraAdult: category?.extraAdult,
                                      extraChild: category?.extraChild,
                                      adults: 1,
                                      childs: 0,
                                      room: "",
                                      roomId: "",
                                    };
                                    calcPrices(index, tempItem);
                                  }}
                                >
                                  {category?.name}
                                </div>
                              ))}
                            </div>
                          }
                        </div>
                      </div>

                      <div className="book-bar-popup-input-container">
                        <img
                          src={"https://www.apcey.com/icons/plus.svg"}
                          alt="+"
                          className="book-bar-popup-input-img"
                          onClick={() => {
                            if (
                              parseInt(item.maxPerson) <=
                              parseInt(item.adults) + parseInt(item.childs)
                            ) {
                              setSimpleAlert(
                                "Alert",
                                item.category +
                                  " max people allowed is " +
                                  item.maxPerson,
                                "OK",
                                4
                              );
                            } else if (!item.category) {
                              setSimpleAlert(
                                "Alert",
                                "Select Room Category first",
                                "OK",
                                4
                              );
                            } else {
                              if (
                                parseInt(item.maxAdult) <= parseInt(item.adults)
                              ) {
                                setSimpleAlert(
                                  "Alert",
                                  item.category +
                                    " max adults allowed is " +
                                    item.maxAdult,
                                  "OK",
                                  4
                                );
                              } else {
                                calcPrices(index, {
                                  ...item,
                                  adults: item.adults + 1,
                                });
                              }
                            }
                          }}
                        />
                        <input
                          className="book-bar-popup-list-input"
                          type="text"
                          value={item.adults}
                          readOnly
                        />
                        <img
                          src="https://www.apcey.com/icons/minus.svg"
                          alt="-"
                          className="book-bar-popup-input-img"
                          onClick={() => {
                            if (item.adults > 1) {
                              calcPrices(index, {
                                ...item,
                                adults: item.adults - 1,
                              });
                            }
                          }}
                        />
                      </div>

                      <div className="book-bar-popup-input-container">
                        <img
                          src="https://www.apcey.com/icons/plus.svg"
                          alt="+"
                          className="book-bar-popup-input-img"
                          onClick={() => {
                            if (
                              parseInt(item.maxPerson) <=
                              parseInt(item.adults) + parseInt(item.childs)
                            ) {
                              setSimpleAlert(
                                "Alert",
                                item.category +
                                  " max people allowed is " +
                                  item.maxPerson,
                                "OK",
                                4
                              );
                            } else if (!item.category) {
                              setSimpleAlert(
                                "Alert",
                                "Select Room Category first",
                                "OK",
                                4
                              );
                            } else {
                              if (
                                parseInt(item.maxChild) <= parseInt(item.childs)
                              ) {
                                setSimpleAlert(
                                  "Alert",
                                  item.category +
                                    " max kids allowed is " +
                                    item.maxChild +
                                    "OK",
                                  4
                                );
                              } else {
                                calcPrices(index, {
                                  ...item,
                                  childs: item.childs + 1,
                                });
                              }
                            }
                          }}
                        />
                        <input
                          className="book-bar-popup-list-input"
                          type="text"
                          value={item.childs}
                          readOnly
                        />
                        <img
                          src="https://www.apcey.com/icons/minus.svg"
                          alt="-"
                          className="book-bar-popup-input-img"
                          onClick={() => {
                            if (item.childs > 0) {
                              calcPrices(index, {
                                ...item,
                                childs: item.childs - 1,
                              });
                            }
                          }}
                        />
                      </div>

                      <p className="book-bar-popup-head-text">{item?.total}</p>

                      <img
                        src="https://www.apcey.com/icons/cancel_round_black.svg"
                        alt="D"
                        className="book-bar-popup-input-img"
                        onClick={() => {
                          calcPrices(index, "");
                        }}
                      />
                    </div>
                  ))}

                  <button
                    className="book-bar-popup-add-btn"
                    onClick={() => {
                      var tempBookObj = [...bookingArray];
                      tempBookObj.push(initialBookModel);
                      setBookingArray(tempBookObj);
                    }}
                  >
                    <img
                      src="https://www.apcey.com/icons/plus.svg"
                      alt="Add"
                      className="book-bar-popup-input-img"
                    />
                    Add Room
                  </button>

                  <p className="book-bar-total-text">{total} LKR</p>
                </div>
              )} */}
            </div>

            <button
              className="book-btn"
              onClick={() => {
                setIsSquareVisible(true);
                // var isAllCategoriesSelected = true;

                // for (let i = 0; i < bookingArray.length; i++) {
                //   var element = bookingArray[i];
                //   if (!element.category) {
                //     isAllCategoriesSelected = false;
                //     break;
                //   }
                // }

                // if (endTime <= parseInt(startTime)) {
                //   setSimpleAlert(
                //     "Warning",
                //     "Ensure that the departure time follows the arrival time",
                //     "OK",
                //     4
                //   );
                // } else if (bookingArray.length < 1) {
                //   setSimpleAlert(
                //     "Warning",
                //     "Ensure that atleast 1 room is selected",
                //     "OK",
                //     4
                //   );
                // } else if (!isAllCategoriesSelected) {
                //   setSimpleAlert(
                //     "Warning",
                //     "Ensure that room categories are selected",
                //     "OK",
                //     4
                //   );
                // } else if (!fName) {
                //   setSimpleAlert(
                //     "Warning",
                //     "Ensure that your First Name is entered",
                //     "OK",
                //     4
                //   );
                // } else if (!lName) {
                //   setSimpleAlert(
                //     "Warning",
                //     "Ensure that your Last Name is entered",
                //     "OK",
                //     4
                //   );
                // } else if (!phone) {
                //   setSimpleAlert(
                //     "Warning",
                //     "Ensure that your phone number is entered",
                //     "OK",
                //     4
                //   );
                // } else {
                //   var duration = Math.floor((endTime - startTime) / 86400000);
                //   var createdTIme = Date.now();
                //   var tempBookingArray = [...bookingArray];
                //   for (let i = 0; i < tempBookingArray.length; i++) {
                //     var element = tempBookingArray[i];
                //     tempBookingArray[i] = {
                //       ...element,
                //       startTime: startTime,
                //       endTime: endTime,
                //       duration: duration,
                //       createdTime: createdTIme,
                //       state: "Booked",
                //       createdBy: "Web",
                //       person: curTitle + " " + fName + " " + lName,
                //       phone: phone,
                //       email: email,
                //       total: total,
                //       nic: "",
                //       roomId: "",
                //       room: "",
                //       note: "",
                //       additional: "",
                //       discountAmount: "",
                //     };
                //   }
                //   setBookSendObj(tempBookingArray);
                //   setConfirmAlert(
                //     "Confirm",
                //     "Are you sure to booking now ?",
                //     "Yes",
                //     "No",
                //     4,
                //     "addBook"
                //   );
                //   // console.log("final Obj: ", tempBookingArray);
                // }
              }}
            >
              Book Now
            </button>
          </div>
        </div>

        {/* Home Title Area*/}
        <div
          className="main-title-container"
          onClick={() => {
            setIsSquareVisible(false);
          }}
        >
          <div className="home-company-title-container">
            {/* Title */}
            <div className="home-title-text">Hotel Apcey</div>

            {/* Sub Title */}
            <div className="home-sub-title">
              Super Convenient. Awesome Value. Clean & Simple
            </div>

            {/* Description */}
            <div className="home-content-text">
              Offering colourful views of Gampaha, Hotel Apcey is situated in
              one of the central points of Sri Lanka with Colombo and Kandy road
              & highway bus stops a short walk away. Curated with a modern edge
              to it Our living spaces provide a relaxing ambience for rest after
              a day of exploring. The friendly staff at our Apcey hotel are
              always ready to see to our guests needs at any time of the day to
              ensure a pleasant stay.
            </div>

            {/* Contact Info */}
            <div className="home-title-contact-container">
              {/* Address */}
              <div className="home-title-contact-item">
                <img
                  src="https://www.apcey.com/icons/location.svg"
                  alt="Location"
                  className="home-title-contact-icon filter-svg"
                />
                <p className="home-title-contact-text">
                  320/B, Galahitiyawa, Ganemulla
                </p>
              </div>

              {/* Call */}
              <div className="home-title-contact-item">
                <img
                  src="https://www.apcey.com/icons/call.svg"
                  alt="Contact"
                  className="home-title-contact-icon filter-svg"
                />
                <a className="home-title-contact-text" href="tel:+94712205076">
                  +94712205076
                </a>
              </div>

              {/* Email */}
              <div className="home-title-contact-item">
                <img
                  src="https://www.apcey.com/icons/email.svg"
                  alt="Email"
                  className="home-title-contact-icon filter-svg"
                />
                <a
                  href="mailto:hotel@acey.com"
                  className="home-title-contact-text"
                >
                  hotel@apcey.com
                </a>
              </div>
            </div>
          </div>
          {/* <img className="home-title-bg-img-left" src={treeLeafIcon} alt="" /> */}
          <img
            className="home-title-bg-img-right"
            src="https://www.apcey.com/icons/tree_leaf.png"
            alt=""
          />
        </div>

        {/* Home Book Direct */}
        <div className="home-book-direct-container">
          {/* Text Content */}
          <div className="home-book-direct-content-container">
            <h1 className="home-book-direct-title">Why Book Direct With Us</h1>
            <h2 className="home-direct-subtitle">Discounts</h2>
            <p className="home-direct-text">
              Take advantage of 10% off throughout the year plus 20% on your
              birthday month!
            </p>
            <h2 className="home-direct-subtitle">Flexible Benefits</h2>
            <p className="home-direct-text">
              Early check-in. Late check-out. (Subject to room availability)
            </p>
            <h2 className="home-direct-subtitle">Free Cancellation Policy</h2>
            <p className="home-direct-text">
              Enjoy free cancellations for bookings cancelled 2 days ahead of
              time. (Except non-cancellable rate)
            </p>
          </div>

          {/* Hotel Image */}
          <img
            src="https://www.apcey.com/images/hotel/hero/hotel_lobby.jpg"
            alt=""
            className="home-book-direct-img"
          />
        </div>

        {/* Properties */}
        <div className="home-counts-container">
          {/* Rooms */}
          <div className="home-counts-item">
            <img
              src="https://www.apcey.com/icons/bed.svg"
              alt=""
              className="home-counts-img filter-svg"
            />
            <p className="home-counts-text">100 Rooms</p>
          </div>

          {/* Staff */}
          <div className="home-counts-item">
            <img
              src="https://www.apcey.com/icons/staff.svg"
              alt=""
              className="home-counts-img filter-svg"
            />
            <p className="home-counts-text">25+ Staff</p>
          </div>

          {/* Persons */}
          <div className="home-counts-item">
            <img
              src="https://www.apcey.com/icons/persons.svg"
              alt=""
              className="home-counts-img filter-svg"
            />
            <p className="home-counts-text">250+ Persons</p>
          </div>

          {/* Area */}
          <div className="home-counts-item">
            <img
              src="https://www.apcey.com/icons/area.svg"
              alt=""
              className="home-counts-img filter-svg"
            />
            <p className="home-counts-text">1000+ SQM Area</p>
          </div>

          {/* Bathrooms */}
          <div className="home-counts-item">
            <img
              src="https://www.apcey.com/icons/bathroom.svg"
              alt=""
              className="home-counts-img filter-svg"
            />
            <p className="home-counts-text">100 Bathrooms</p>
          </div>

          {/* Swimming Pools */}
          <div className="home-counts-item">
            <img
              src="https://www.apcey.com/icons/swimming_pool.svg"
              alt=""
              className="home-counts-img filter-svg"
            />
            <p className="home-counts-text">2 Pools</p>
          </div>

          {/* Bars */}
          <div className="home-counts-item">
            <img
              src="https://www.apcey.com/icons/bar.svg"
              alt=""
              className="home-counts-img filter-svg"
            />
            <p className="home-counts-text">2 Bars</p>
          </div>

          {/* Car Parks */}
          <div className="home-counts-item">
            <img
              src="https://www.apcey.com/icons/parking.svg"
              alt=""
              className="home-counts-img filter-svg"
            />
            <p className="home-counts-text">2 Car Parks</p>
          </div>
        </div>

        {/* Home Amenities*/}
        <div className="home-amenities-container">
          <h2 className="home-amenities-title">AMENITIES</h2>
          <div className="home-amenities-item-container">
            {/* Coffee/Tea-making Facilities */}
            <div className="home-amenities-item">
              <img
                src="https://www.apcey.com/icons/coffee_machine.svg"
                alt="Coffee"
                className="home-amenities-item-icon filter-svg"
              />
              <p className="home-amenities-item-text">
                Coffee/Tea-making Facilities
              </p>
            </div>

            {/* Internet - Connected LED TV */}
            <div className="home-amenities-item">
              <img
                src="https://www.apcey.com/icons/tv.svg"
                alt="Tv"
                className="home-amenities-item-icon filter-svg"
              />
              <p className="home-amenities-item-text">
                Internet - Connected LED TV
              </p>
            </div>

            {/* In-Room Filtered Drinking Water */}
            <div className="home-amenities-item">
              <img
                src="https://www.apcey.com/icons/water_filter.svg"
                alt="Water"
                className="home-amenities-item-icon filter-svg"
              />
              <p className="home-amenities-item-text">
                In-Room Filtered Drinking Water
              </p>
            </div>

            {/* Mini Fridge* (selected rooms) */}
            <div className="home-amenities-item">
              <img
                src="https://www.apcey.com/icons/fridge.svg"
                alt="Fridge"
                className="home-amenities-item-icon filter-svg"
              />
              <p className="home-amenities-item-text">Mini Fridge</p>
            </div>

            {/* Branded Luxurious Pocket Spring Mattress */}
            <div className="home-amenities-item">
              <img
                src="https://www.apcey.com/icons/bed_black.svg"
                alt="Bed"
                className="home-amenities-item-icon filter-svg"
              />
              <p className="home-amenities-item-text">
                Luxurious Spring Mattress
              </p>
            </div>

            {/* Toilet with Shower (hot/cold) */}
            <div className="home-amenities-item">
              <img
                src="https://www.apcey.com/icons/shower.svg"
                alt="Shower"
                className="home-amenities-item-icon filter-svg"
              />
              <p className="home-amenities-item-text">
                Toilet with Shower (hot/cold)
              </p>
            </div>

            {/* High-Speed WiFi */}
            <div className="home-amenities-item">
              <img
                src="https://www.apcey.com/icons/wifi.svg"
                alt="WiFi"
                className="home-amenities-item-icon filter-svg"
              />
              <p className="home-amenities-item-text">High-Speed WiFi</p>
            </div>

            {/* Parking on-site */}
            <div className="home-amenities-item">
              <img
                src="https://www.apcey.com/icons/parking.svg"
                alt="Park"
                className="home-amenities-item-icon filter-svg"
              />
              <p className="home-amenities-item-text">Parking on-site</p>
            </div>

            {/* Air-conditioned */}
            <div className="home-amenities-item">
              <img
                src="https://www.apcey.com/icons/ac.svg"
                alt="AC"
                className="home-amenities-item-icon filter-svg"
              />
              <p className="home-amenities-item-text">Air-conditioned</p>
            </div>

            {/* 24 hours hotel reception */}
            <div className="home-amenities-item">
              <img
                src="https://www.apcey.com/icons/reception.svg"
                alt="Recept"
                className="home-amenities-item-icon filter-svg"
              />
              <p className="home-amenities-item-text">
                24 Hours Hotel Reception
              </p>
            </div>

            {/* Luggage storage service */}
            <div className="home-amenities-item">
              <img
                src="https://www.apcey.com/icons/luggage.svg"
                alt="Luggage"
                className="home-amenities-item-icon filter-svg"
              />
              <p className="home-amenities-item-text">
                Luggage Storage Service
              </p>
            </div>

            {/* Emergency Medical Service */}
            <div className="home-amenities-item">
              <img
                src="https://www.apcey.com/icons/medical.svg"
                alt="Medical"
                className="home-amenities-item-icon filter-svg"
              />
              <p className="home-amenities-item-text">
                Emergency Medical Service
              </p>
            </div>

            {/* Room Service */}
            <div className="home-amenities-item">
              <img
                src="https://www.apcey.com/icons/room_service.svg"
                alt="Medical"
                className="home-amenities-item-icon filter-svg"
              />
              <p className="home-amenities-item-text">Room Service</p>
            </div>

            {/* Bar Service */}
            <div className="home-amenities-item">
              <img
                src="https://www.apcey.com/icons/bar.svg"
                alt="Medical"
                className="home-amenities-item-icon filter-svg"
              />
              <p className="home-amenities-item-text">Bar Service</p>
            </div>

            {/* Swimming Pool & Leasure Activities */}
            <div className="home-amenities-item">
              <img
                src="https://www.apcey.com/icons/swimming_pool.svg"
                alt="Medical"
                className="home-amenities-item-icon filter-svg"
              />
              <p className="home-amenities-item-text">
                Swimming Pool & Leasure Sports
              </p>
            </div>
          </div>
        </div>

        {/* Rooms */}
        <div className="home-rooms-container">
          <h1 className="home-rooms-title">OUR ROOMS</h1>
          <div className="home-rooms-item-container">
            {/* Room List */}
            {categories?.map((item, index) => (
              <div className="home-rooms-item">
                <div
                  className="home-rooms-item-image-container"
                  onClick={() => {
                    setGallery(0, [
                      item.photo1,
                      item.photo2,
                      item.photo3,
                      item.photo4,
                      item.photo5,
                    ]);
                  }}
                >
                  <img
                    src={item?.photo1}
                    alt=""
                    className="home-rooms-item-img"
                  />

                  <img
                    src="https://www.apcey.com/icons/magnify.svg"
                    alt=""
                    className="home-rooms-magnify-icon"
                  />
                </div>

                <h2 className="home-rooms-item-title">{item?.name}</h2>
                <p className="home-rooms-item-text">
                  Max : {item?.maxPerson} People | {item.maxAdult} Adults |{" "}
                  {item.maxChild} Kids
                </p>
                <p className="home-rooms-item-text">
                  Initial Price : {item?.initPrice} LKR
                </p>
                {/* <p className="home-rooms-item-person">Max Childs: {item?.maxPerson}</p> */}
              </div>
            ))}
          </div>
        </div>

        {/* Location */}
        <div className="home-location-photo-container">
          <h1 className="home-location-title">Our Location</h1>

          <img
            src="https://www.apcey.com/images/hotel/hero/apcey_location.jpg"
            alt=""
            className="home-location-img"
          />

          <a
            href="https://www.google.com/maps?q=7.063543021384136,79.95599304634153"
            target="Apcey Technologies"
            rel="noopener noreferrer"
            className="open-in-map-text"
          >
            Open in Maps
          </a>
        </div>

        {/* Reviews */}
        <div className="home-reviews-container">
          {/* Reviews Title */}
          <div className="home-reviews-title">
            Reviews - What Our Guests Say
          </div>

          {/* Review Items */}
          <div className="home-reviews-items-container">
            {/* Quote 1 */}
            <div className="home-reviews-item home-reviews-bg-dark">
              <img
                src="https://www.apcey.com/icons/quote_start.svg"
                alt=""
                className="home-reviews-quote-img"
              />
              <div className="home-review-content-layout">
                <p className="home-reviews-text">
                  A wonderful place, beautiful luxurious complete hotel with
                  attractive furnishings. Lots of space, comfort and privacy.
                  Very friendly staff and great service.
                </p>
                <p className="home-reviews-person">Dr. Erwin De</p>
                <p className="home-reviews-date">september | 2020</p>
              </div>
              <img
                src="https://www.apcey.com/icons/quote_end.svg"
                alt=""
                className="home-reviews-quote-img"
              />
            </div>

            {/* Quote 2 */}
            <div className="home-reviews-item home-reviews-bg-light">
              <img
                src="https://www.apcey.com/icons/quote_start.svg"
                alt=""
                className="home-reviews-quote-img"
              />
              <div className="home-review-content-layout">
                <p className="home-reviews-text">
                  Beautiful hotel, lots of space, clean, very well-equipped,
                  wonderful wellness. In short; a wonderful rooms to stay with a
                  large group. Lot of leasure activities to experience in
                  leasure time. Really enjoyed pool, gaming & sports.
                </p>
                <p className="home-reviews-person">Daena Klint</p>
                <p className="home-reviews-date">september | 2020</p>
              </div>
              <img
                src="https://www.apcey.com/icons/quote_end.svg"
                alt=""
                className="home-reviews-quote-img"
              />
            </div>

            {/* Quote 3 */}
            <div className="home-reviews-item home-reviews-bg-dark">
              <img
                src="https://www.apcey.com/icons/quote_start.svg"
                alt=""
                className="home-reviews-quote-img"
              />
              <div className="home-review-content-layout">
                <p className="home-reviews-text">
                  It was a brilliant experience. Really enjoyed location and
                  their service. Here is one of the most memorable times in my
                  life. We'll definitely be back for sure.
                </p>
                <p className="home-reviews-person">Andrew Kyle</p>
                <p className="home-reviews-date">september | 2020</p>
              </div>
              <img
                src="https://www.apcey.com/icons/quote_end.svg"
                alt=""
                className="home-reviews-quote-img"
              />
            </div>

            {/* Quote 4 */}
            <div className="home-reviews-item home-reviews-bg-light">
              <img
                src="https://www.apcey.com/icons/quote_start.svg"
                alt=""
                className="home-reviews-quote-img"
              />
              <div className="home-review-content-layout">
                <p className="home-reviews-text">
                  Accommodation is perfect. Has everything you need and then
                  some extra. Plenty of room for activities and plenty of
                  facilities. Recommended for summer and winter stays.
                </p>
                <p className="home-reviews-person">Sabreine Hamilton</p>
                <p className="home-reviews-date">september | 2020</p>
              </div>
              <img
                src="https://www.apcey.com/icons/quote_end.svg"
                alt=""
                className="home-reviews-quote-img"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
