// App.js
import React from "react";
import { BrowserRouter, Route, Routes, Outlet } from "react-router-dom";
import CommonLayout from "./Components/CommonLayout";
import Home from "./Components/Home/Home";
import Rooms from "./Components/Rooms/Rooms";
import Gallery from "./Components/Gallery/Gallery";
import Faq from "./Components/Faq/Faq";
import About from "./Components/About/About";
import Contact from "./Components/Contact/Contact";
import ScrollToTop from "./ScrollToTop";
import { StateProvider } from "./StateContext";
import AlertsUI from "./Components/Alerts/AlertsUI";

const App = () => {
  return (
    <StateProvider>

    <BrowserRouter>
      <ScrollToTop />
      <AlertsUI />
      <Routes>
        <Route
          path="/"
          element={
            <CommonLayout>
              <Outlet />
            </CommonLayout>
          }
        >
          <Route index element={<Home />} />
          <Route path="home" element={<Home />} />
          <Route path="rooms" element={<Rooms />} />
          <Route path="faq" element={<Faq />} />
          <Route path="about" element={<About />} />
          <Route path="contact" element={<Contact />} />
          <Route path="gallery" element={<Gallery />} />
          <Route path="*" element={<Home />} />
        </Route>
      </Routes>
    </BrowserRouter>
    </StateProvider>

  );
};

export default App;
