import "./Rooms.css";
import ImageGallery from "../ImageGallery";
import { useState, useEffect } from "react";
import { postReq } from "../../api/APIClient";

export default function Rooms() {

  useEffect(() => {
    checkCategoriesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkCategoriesData = async () => {
    var lastSync = await JSON.parse(localStorage.getItem("lastSynced"));
    var localData = await JSON.parse(
      localStorage.getItem("roomCategoriesData")
    );
    if (localData) {
      setCategories(localData);
    }
    if (!localData || lastSync + 8000000 < Date.now()) {
      getNewCategoriesData();
    }
  };

  const getNewCategoriesData = async () => {
    // setIsLoading(true);
    const response = await postReq(
      "roomCategoriesData",
      { recordCount: 0 },
      true,
      true
    );
    // setIsLoading(false);

    if (response.status === "success") {
      var newCategories = response?.records;
      setCategories(newCategories);
      localStorage.setItem("lastSynced", Date.now());
    }
  };

  const [categories, setCategories] = useState();

  const [currentImageSet, setCurrentImageSet] = useState([]);

  const [galleryOpen, setGalleryOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const openGallery = () => {
    setGalleryOpen(true);
  };

  const closeGallery = () => {
    setGalleryOpen(false);
    setCurrentImageSet([]);
  };

  const setGallery = (newIndex, item) => {
    var photoArray = [
      item?.photo1,
      item?.photo2,
      item?.photo3,
      item?.photo4,
      item?.photo5,
    ];
    setCurrentImageIndex(newIndex);
    setCurrentImageSet(photoArray);
  };

  useEffect(() => {
    if (currentImageSet.length > 0) {
      openGallery();
    }
  }, [currentImageSet]);

  return (
    <>
      {/* Image Gallery */}
      {galleryOpen && (
        <div className="gallery-container">
          <ImageGallery
            images={currentImageSet}
            initialIndex={currentImageIndex}
            onClose={closeGallery}
          />
        </div>
      )}
      
      {/* Rooms Page */}
      <div className="web-pages-main-container">
        {/* Hero & Booking bar Area */}
        <div className="hero-main">
          {/* Hero Image */}
          <img
            className="about-hero-img"
            src="https://img.etimg.com/thumb/msid-64734932,width-1920,height-1080,imgsize-379180,resizemode-0/suite-yourself.jpg"
            alt=""
          ></img>
        </div>

        {/* Home Title Area*/}
        <div className="main-title-container">
          <div className="home-company-title-container">
            {/* Title */}
            <h1 className="rooms-title">Our Rooms</h1>

            {/* Sub Title */}
            <div className="home-sub-title">
              Indulge in Unparalleled Comfort and Style
            </div>

            {/* Description */}
            <div className="home-content-text">
              Discover a world of refined elegance and contemporary comfort at
              Apcey Hotels. Each of our thoughtfully designed rooms is a haven
              of tranquility, seamlessly blending modern amenities with timeless
              charm. Whether you choose any category, rest assured that your
              stay will be defined by unparalleled luxury and personalized
              service. Welcome to a sanctuary where every detail is crafted to
              make your experience truly exceptional.
            </div>
          </div>
          {/* <img className="home-title-bg-img-left" src={treeLeafIcon} alt="" /> */}
          {/* <img className="home-title-bg-img-right" src={treeLeafIcon} alt="" /> */}
        </div>

        {/* Rooms */}
        <div className="rooms-rooms-container">
          <h1 className="rooms-rooms-title">Room Categories</h1>
          <div className="rooms-rooms-item-container">
            {/* Room List */}
            {categories?.map((item, index) => (
              <div className="rooms-rooms-item">
                {/* Room Title */}
                <h2 className="rooms-rooms-item-title">{item?.name}</h2>
                {/* Room Description */}
                <p className="rooms-item-content">{item.description}</p>

                <div className="rooms-rooms-item-numbers-container">
                  <p className="rooms-rooms-item-numbers-text">Beds : 2</p>
                  <p className="rooms-rooms-item-numbers-text">Bathrooms : 1</p>
                  <p className="rooms-rooms-item-numbers-text">
                    Initial Price : {item?.initPrice} LKR
                  </p>
                  <p className="rooms-rooms-item-numbers-text">
                    Size : 250 SQM
                  </p>
                  <p className="rooms-rooms-item-numbers-text">
                    Max People : {item?.maxPerson}
                  </p>
                  <p className="rooms-rooms-item-numbers-text">
                    Max Adults : {item?.maxAdult}
                  </p>
                  <p className="rooms-rooms-item-numbers-text">
                    Max Kids : {item?.maxChild}
                  </p>
                  <p className="rooms-rooms-item-numbers-text">
                    WiFi : Available
                  </p>
                </div>
                {/* <img
                  src={item?.photo1}
                  alt=""
                  className="rooms-rooms-item-img"
                /> */}

                {/* Rooms Image Gallery */}
                <div className="rooms-rooms-img-container">
                  <div
                    className="rooms-img-item-container"
                    onClick={() => {
                      // setCurrentImageSet(photoArray);
                      setGallery(0, item);
                    }}
                  >
                    <img src={item.photo1} alt="" className="rooms-img" />
                    <img src="https://www.apcey.com/icons/magnify.svg" alt="" className="magnify-icon" />
                  </div>

                  <div
                    className="rooms-img-item-container"
                    onClick={() => {
                      setGallery(1, item);
                    }}
                  >
                    <img src={item.photo2} alt="" className="rooms-img" />
                    <img src="https://www.apcey.com/icons/magnify.svg" alt="" className="magnify-icon" />
                  </div>

                  <div
                    className="rooms-img-item-container"
                    onClick={() => {
                      setGallery(2, item);
                    }}
                  >
                    <img src={item.photo3} alt="" className="rooms-img" />
                    <img src="https://www.apcey.com/icons/magnify.svg" alt="" className="magnify-icon" />
                  </div>

                  <div
                    className="rooms-img-item-container"
                    onClick={() => {
                      setGallery(3, item);
                    }}
                  >
                    <img src={item.photo4} alt="" className="rooms-img" />
                    <img src="https://www.apcey.com/icons/magnify.svg" alt="" className="magnify-icon" />
                  </div>

                  <div
                    className="rooms-img-item-container"
                    onClick={() => {
                      setGallery(4, item);
                    }}
                  >
                    <img src={item.photo5} alt="" className="rooms-img" />
                    <img src="https://www.apcey.com/icons/magnify.svg" alt="" className="magnify-icon" />
                  </div>
                </div>

                {/* {parseInt(index) + 1 < categories.length && (
                  <div className="rooms-rooms-seperator"></div>
                )} */}
              </div>
            ))}
          </div>
        </div>

        {/* Land House */}
        {/* <div className="rooms-container">
          <p className="rooms-title">Land House</p>
          <p className="rooms-content">
            The house has everything to make your stay complete. You are assured
            of a relaxing and relaxing holiday in a warm ambiance. The house is
            fully equipped, such as a digital music system, free WiFi and
            private parking. Your stay includes made beds, coffee cups, kitchen
            package, towel package and final cleaning so that you don't have to
            think about anything and can completely relax. The villa is
            non-smoking and pets are not allowed.
          </p>

          <div className="rooms-img-container">
            <div
              className="rooms-img-item-container"
              onClick={() => {
                setGallery(0, images1);
              }}
            >
              <img src={landHouse1} alt="" className="rooms-img" />
              <img src="https://www.apcey.com/icons/magnify.svg" alt="" className="magnify-icon" />
            </div>

            <div
              className="rooms-img-item-container"
              onClick={() => {
                setGallery(1, images1);
              }}
            >
              <img src={landHouse2} alt="" className="rooms-img" />
              <img src="https://www.apcey.com/icons/magnify.svg" alt="" className="magnify-icon" />
            </div>

            <div
              className="rooms-img-item-container"
              onClick={() => {
                setGallery(2, images1);
              }}
            >
              <img src={landHouse3} alt="" className="rooms-img" />
              <img src="https://www.apcey.com/icons/magnify.svg" alt="" className="magnify-icon" />
            </div>

            <div
              className="rooms-img-item-container"
              onClick={() => {
                setGallery(3, images1);
              }}
            >
              <img src={landHouse4} alt="" className="rooms-img" />
              <img src="https://www.apcey.com/icons/magnify.svg" alt="" className="magnify-icon" />
            </div>

            <div
              className="rooms-img-item-container"
              onClick={() => {
                setGallery(4, images1);
              }}
            >
              <img src={landHouse5} alt="" className="rooms-img" />
              <img src="https://www.apcey.com/icons/magnify.svg" alt="" className="magnify-icon" />
            </div>
          </div>
        </div> */}

        {/* Kitchen */}
        {/* <div className="rooms-container">
          <p className="rooms-title">Kitchen</p>
          <p className="rooms-content">
            There is a spacious kitchen where, in addition to a large cooking
            island with gas stove, all important equipment is present (ovens,
            refrigerators, dishwashers); therefore perfectly suitable for
            cooking and dining with the entire group. Three large dining tables
            with long benches and lots of cushions for an optimal atmosphere in
            the dining room! There is also an extra long table in the kitchen
            itself, possibly for the little ones.
          </p>

          <div className="rooms-img-container">
            <div
              className="rooms-img-item-container"
              onClick={() => {
                setGallery(0, images2);
              }}
            >
              <img src={kitchen1} alt="" className="rooms-img" />
              <img src="https://www.apcey.com/icons/magnify.svg" alt="" className="magnify-icon" />
            </div>

            <div
              className="rooms-img-item-container"
              onClick={() => {
                setGallery(1, images2);
              }}
            >
              <img src={kitchen2} alt="" className="rooms-img" />
              <img src="https://www.apcey.com/icons/magnify.svg" alt="" className="magnify-icon" />
            </div>

            <div
              className="rooms-img-item-container"
              onClick={() => {
                setGallery(2, images2);
              }}
            >
              <img src={kitchen3} alt="" className="rooms-img" />
              <img src="https://www.apcey.com/icons/magnify.svg" alt="" className="magnify-icon" />
            </div>

            <div
              className="rooms-img-item-container"
              onClick={() => {
                setGallery(3, images2);
              }}
            >
              <img src={kitchen4} alt="" className="rooms-img" />
              <img src="https://www.apcey.com/icons/magnify.svg" alt="" className="magnify-icon" />
            </div>

            <div
              className="rooms-img-item-container"
              onClick={() => {
                setGallery(4, images2);
              }}
            >
              <img src={kitchen5} alt="" className="rooms-img" />
              <img src="https://www.apcey.com/icons/magnify.svg" alt="" className="magnify-icon" />
            </div>
          </div>
        </div> */}

        {/* Living */}
        {/* <div className="rooms-container">
          <p className="rooms-title">Living</p>
          <p className="rooms-content">
            The living room has large sliding glass doors on all sides, giving
            you a spectacular view of the fantastic garden. After a day
            outdoors, you can relax by the fireplace in the luxurious seating
            area.
          </p>

          <div className="rooms-img-container">
            <div
              className="rooms-img-item-container"
              onClick={() => {
                setGallery(0, images3);
              }}
            >
              <img src={living1} alt="" className="rooms-img" />
              <img src="https://www.apcey.com/icons/magnify.svg" alt="" className="magnify-icon" />
            </div>

            <div
              className="rooms-img-item-container"
              onClick={() => {
                setGallery(1, images3);
              }}
            >
              <img src={living2} alt="" className="rooms-img" />
              <img src="https://www.apcey.com/icons/magnify.svg" alt="" className="magnify-icon" />
            </div>

            <div
              className="rooms-img-item-container"
              onClick={() => {
                setGallery(2, images3);
              }}
            >
              <img src={living3} alt="" className="rooms-img" />
              <img src="https://www.apcey.com/icons/magnify.svg" alt="" className="magnify-icon" />
            </div>

            <div
              className="rooms-img-item-container"
              onClick={() => {
                setGallery(3, images3);
              }}
            >
              <img src={living4} alt="" className="rooms-img" />
              <img src="https://www.apcey.com/icons/magnify.svg" alt="" className="magnify-icon" />
            </div>

            <div
              className="rooms-img-item-container"
              onClick={() => {
                setGallery(4, images3);
              }}
            >
              <img src={living5} alt="" className="rooms-img" />
              <img src="https://www.apcey.com/icons/magnify.svg" alt="" className="magnify-icon" />
            </div>
          </div>
        </div> */}

        {/* Bedroom */}
        {/* <div className="rooms-container">
          <p className="rooms-title">bedrooms</p>
          <p className="rooms-content">
            Experience convenience and comfort in the spacious bedrooms. On the
            first floor and in the basement of the country house there are 3
            beautiful suites with a large king-size bed, 6 double bedrooms, 2 of
            which have an extra loft bed for the children, an extra large
            bedroom that can sleep four people and a children's room especially
            for the little ones.
          </p>

          <div className="rooms-img-container">
            <div
              className="rooms-img-item-container"
              onClick={() => {
                setGallery(0, images4);
              }}
            >
              <img src={bedroom1} alt="" className="rooms-img" />
              <img src="https://www.apcey.com/icons/magnify.svg" alt="" className="magnify-icon" />
            </div>

            <div
              className="rooms-img-item-container"
              onClick={() => {
                setGallery(1, images4);
              }}
            >
              <img src={bedroom2} alt="" className="rooms-img" />
              <img src="https://www.apcey.com/icons/magnify.svg" alt="" className="magnify-icon" />
            </div>

            <div
              className="rooms-img-item-container"
              onClick={() => {
                setGallery(2, images4);
              }}
            >
              <img src={bedroom3} alt="" className="rooms-img" />
              <img src="https://www.apcey.com/icons/magnify.svg" alt="" className="magnify-icon" />
            </div>

            <div
              className="rooms-img-item-container"
              onClick={() => {
                setGallery(3, images4);
              }}
            >
              <img src={bedroom4} alt="" className="rooms-img" />
              <img src="https://www.apcey.com/icons/magnify.svg" alt="" className="magnify-icon" />
            </div>

            <div
              className="rooms-img-item-container"
              onClick={() => {
                setGallery(4, images4);
              }}
            >
              <img src={bedroom5} alt="" className="rooms-img" />
              <img src="https://www.apcey.com/icons/magnify.svg" alt="" className="magnify-icon" />
            </div>
          </div>
        </div> */}

        {/* Bathrooms */}
        {/* <div className="rooms-container">
          <p className="rooms-title">Bathrooms</p>
          <p className="rooms-content">
            The 3 suites have beautiful ensuite bathrooms, with a double sink
            and a spectacular rain shower and/or bath. Delicious products are
            used for the shampoo, conditioner and body & handwash. There are
            also 3 separate bathrooms in the country house and 5 bedrooms have
            their own sink.
          </p>

          <div className="rooms-img-container">
            <div
              className="rooms-img-item-container"
              onClick={() => {
                setGallery(0, images5);
              }}
            >
              <img src={bathroom1} alt="" className="rooms-img" />
              <img src="https://www.apcey.com/icons/magnify.svg" alt="" className="magnify-icon" />
            </div>

            <div
              className="rooms-img-item-container"
              onClick={() => {
                setGallery(1, images5);
              }}
            >
              <img src={bathroom2} alt="" className="rooms-img" />
              <img src="https://www.apcey.com/icons/magnify.svg" alt="" className="magnify-icon" />
            </div>

            <div
              className="rooms-img-item-container"
              onClick={() => {
                setGallery(2, images5);
              }}
            >
              <img src={bathroom3} alt="" className="rooms-img" />
              <img src="https://www.apcey.com/icons/magnify.svg" alt="" className="magnify-icon" />
            </div>

            <div
              className="rooms-img-item-container"
              onClick={() => {
                setGallery(3, images5);
              }}
            >
              <img src={bathroom4} alt="" className="rooms-img" />
              <img src="https://www.apcey.com/icons/magnify.svg" alt="" className="magnify-icon" />
            </div>

            <div
              className="rooms-img-item-container"
              onClick={() => {
                setGallery(4, images5);
              }}
            >
              <img src={bathroom5} alt="" className="rooms-img" />
              <img src="https://www.apcey.com/icons/magnify.svg" alt="" className="magnify-icon" />
            </div>
          </div>
        </div> */}

        {/* Entertainment */}
        {/* <div className="rooms-container">
          <p className="rooms-title">Entertainment</p>
          <p className="rooms-content">
            In the basement there is entertainment for young and old. a home
            cinema, billiards and table football and a large bar. The entire
            country house is also equipped with a digital music system.
          </p>

          <div className="rooms-img-container">
            <div
              className="rooms-img-item-container"
              onClick={() => {
                setGallery(0, images6);
              }}
            >
              <img src={entertainment1} alt="" className="rooms-img" />
              <img src="https://www.apcey.com/icons/magnify.svg" alt="" className="magnify-icon" />
            </div>

            <div
              className="rooms-img-item-container"
              onClick={() => {
                setGallery(1, images6);
              }}
            >
              <img src={entertainment2} alt="" className="rooms-img" />
              <img src="https://www.apcey.com/icons/magnify.svg" alt="" className="magnify-icon" />
            </div>

            <div
              className="rooms-img-item-container"
              onClick={() => {
                setGallery(2, images6);
              }}
            >
              <img src={entertainment3} alt="" className="rooms-img" />
              <img src="https://www.apcey.com/icons/magnify.svg" alt="" className="magnify-icon" />
            </div>

            <div
              className="rooms-img-item-container"
              onClick={() => {
                setGallery(3, images6);
              }}
            >
              <img src={entertainment4} alt="" className="rooms-img" />
              <img src="https://www.apcey.com/icons/magnify.svg" alt="" className="magnify-icon" />
            </div>

            <div
              className="rooms-img-item-container"
              onClick={() => {
                setGallery(4, images6);
              }}
            >
              <img src={entertainment5} alt="" className="rooms-img" />
              <img src="https://www.apcey.com/icons/magnify.svg" alt="" className="magnify-icon" />
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
}
