import "./Footer.css";

export default function Footer() {
  return (
    <>
      <div className="footer-main-container">
        <div className="footer-social-media-container">
          <p className="footer-social-title">Social</p>
          <div className="footer-social-img-container">
            {/* facebook */}
            <a
              href="https://www.facebook.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://www.apcey.com/icons/social_media/facebook.svg"
                alt=""
                className="footer-social-img filter-svg-2"
                href="https://www.facebook.com"
              />
            </a>

            {/* Instagram */}
            <a
              href="https://www.instagram.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://www.apcey.com/icons/social_media/instagram.svg"
                alt=""
                className="footer-social-img filter-svg-2"
              />
            </a>

            {/* Whatsapp */}
            <a
              href="https://web.whatsapp.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://www.apcey.com/icons/social_media/whatsapp2.svg"
                alt=""
                className="footer-social-img filter-svg-2"
              />
            </a>

            {/* Youtube */}
            <a
              href="https://www.youtube.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://www.apcey.com/icons/social_media/youtube.svg"
                alt=""
                className="footer-social-img filter-svg-2"
              />
            </a>
          </div>
        </div>

        <div className="footer-partners-container">
          <p className="footer-partners-title">Partners</p>
          <div className="footer-partners-item-container">
            <img
              src="https://www.apcey.com/icons/villa.svg"
              alt=""
              className="footer-partners-img1 filter-svg-2"
            />
            <img
              src="https://www.apcey.com/icons/apcey_gray_light.svg"
              alt=""
              className="footer-partners-img2 filter-svg-2"
            />
          </div>
        </div>

        <div className="footer-contact-container">
          <p className="footer-contact-title">Contact</p>
          <p className="footer-contact-name">Apcey Hotel & Resort</p>
          <p className="footer-contact-address">
            320/B, Galahitiyawa, Ganemulla
          </p>
          <p className="footer-contact-telephone">
            Telephone : +94 71 220 50 76
          </p>
          <p className="footer-contact-email">Email : info@apceyhotels.com</p>
        </div>
      </div>
      <div className="footer-copyright-text">
        This website is a demo website developed by Apcey Technologies. All
        Rights Reserved
      </div>
    </>
  );
}
