import { useRef, useEffect } from "react";
import "./Alerts.css";
import { useStateContext } from "../../StateContext";

export default function ConfirmAlert() {
  const buttonRef = useRef(null);
  const {
    setIsConfirmAlert,
    setConfirmAlertEvent,
    confirmAlertTitle,
    confirmAlertMsg,
    confirmAlertPositiveBtn,
    confirmAlertNegativeBtn,
    confirmAlertType,
    confirmEventName,
  } = useStateContext();

  useEffect(() => {
    if (buttonRef.current) {
      buttonRef.current.focus();
    }

    function handleKeyDown(event) {
      if (event.key === "Escape" || event.key === "Control") {
        setIsConfirmAlert(false);
      } else if (event.key === "Shift") {
        setConfirmAlertEvent(confirmEventName);
        setIsConfirmAlert(false);
      }
    }

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [
    setIsConfirmAlert,
    setConfirmAlertEvent,
    confirmEventName,
  ]); // Include the dependencies in the array

  return (
    <div className="alert-main-container">
      <div className="alert-dialog-container">
        <div
          className={`alert-header ${
            confirmAlertType === 1
              ? "alert-bg-green"
              : confirmAlertType === 2
              ? "alert-bg-red"
              : confirmAlertType === 3
              ? "alert-bg-yellow"
              : "alert-bg-blue"
          }`}
        >
          <img className="alert-img" src="https://www.apcey.com/icons/question.svg" alt="Confirm" />
          <p className="alert-title">{confirmAlertTitle}</p>
        </div>

        <div className="alert-body">
          <div className="alert-msg">{confirmAlertMsg}</div>

          <div className="alert-btn-container">
            {/* Positive Button */}
            <button
              ref={buttonRef}
              className={`alert-btn confirm-btn ${
                confirmAlertType === 1
                  ? "alert-bg-green"
                  : confirmAlertType === 2
                  ? "alert-bg-red"
                  : confirmAlertType === 3
                  ? "alert-bg-yellow"
                  : "alert-bg-blue"
              }`}
              onClick={() => {
                setConfirmAlertEvent(confirmEventName);
                setIsConfirmAlert(false);
              }}
            >
              {confirmAlertPositiveBtn}
            </button>

            {/* Negative Button */}
            <button
              ref={buttonRef}
              className={`alert-btn confirm-btn ${
                confirmAlertType === 2 ? "alert-bg-green" : "alert-bg-red"
              }`}
              onClick={() => {
                setIsConfirmAlert(false);
              }}
            >
              {confirmAlertNegativeBtn}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}