import "./Faq.css";
import { useState } from "react";

export default function Faq() {

  var tempFaqs = [
    {
      question: "What are the check-in and check-out times at the hotel?",
      answer:
        "Our standard check-in time is at 3:00 PM, and check-out is at 11:00 AM. Early check-in and late check-out are subject to availability and may incur additional charges.",
      state: false,
    },
    {
      question: "Do you offer airport shuttle service?",
      answer:
        "Yes, we provide airport shuttle service for our guests. Please contact our front desk for more information and to schedule your shuttle.",
      state: false,
    },
    {
      question: "Is parking available at the hotel?",
      answer:
        "We offer convenient on-site parking for our guests. There may be a parking fee, so please inquire at check-in.",
      state: false,
    },
    {
      question: "Do you have a restaurant on-site?",
      answer:
        "Yes, we have an on-site restaurant serving a variety of delicious dishes. Guests can also enjoy room service during restaurant hours.",
      state: false,
    },
    {
      question: "Is Wi-Fi available for guests?",
      answer:
        "We offer complimentary high-speed Wi-Fi access throughout the hotel for our guests.",
      state: false,
    },
    {
      question: "What amenities are included in the room rate?",
      answer:
        "Our room rates include amenities such as free Wi-Fi, access to the fitness center, and daily housekeeping. Some room types may offer additional amenities.",
      state: false,
    },
    {
      question: "Are pets allowed at the hotel?",
      answer:
        "We welcome service animals, but we do not allow pets in our guest rooms. Please contact us in advance if you have a service animal.",
      state: false,
    },
    {
      question: "Can I cancel or modify my reservation?",
      answer:
        "Our cancellation and modification policies vary depending on the type of reservation you've made. Please refer to your booking confirmation for details or contact our reservations team.",
      state: false,
    },
    {
      question: "Do you have a fitness center?",
      answer:
        "Yes, we have a fully equipped fitness center available for our guests. Stay active during your stay!",
      state: false,
    },
    {
      question: "Is smoking allowed in the hotel rooms?",
      answer:
        "We have a strict no-smoking policy in all of our guest rooms and indoor public areas. Designated smoking areas are available outside the hotel.",
      state: false,
    },
    {
      question: "Do you offer room service?",
      answer:
        "Yes, we provide room service during restaurant hours. You can enjoy a meal or snack in the comfort of your room.",
      state: false,
    },
    {
      question: "What payment methods do you accept?",
      answer:
        "We accept a variety of payment methods, including major credit cards (Visa, MasterCard, American Express), as well as cash and mobile payment options. Please note that a valid credit card is required at the time of check-in for incidental charges.",
      state: false,
    },
    {
      question: "Is breakfast included in the room rate?",
      answer:
        "Depending on your room type and package, breakfast may be included. Please check your reservation details or contact our front desk for information.",
      state: false,
    },
    {
      question: "Can I host events or meetings at the hotel?",
      answer:
        "Yes, we offer event and meeting spaces for various occasions. Please contact our events team for more information and to book your event.",
      state: false,
    },
    {
      question: "Is there a swimming pool on-site?",
      answer:
        "We have a beautiful outdoor swimming pool available for our guests to relax and enjoy during their stay.",
      state: false,
    },
    {
      question: "What attractions and activities are nearby?",
      answer:
        "Our hotel is conveniently located near popular attractions, shopping centers, and dining options. Our concierge can provide recommendations and assist with bookings.",
      state: false,
    },
  ];

  const midpoint = Math.ceil(tempFaqs.length / 2);
  const [firstHalf, setFirstHalf] = useState(tempFaqs.slice(0, midpoint));
  const [secondHalf, setSecondHalf] = useState(tempFaqs.slice(midpoint));

  // const [faqs, setFaqs] = useState(tempFaqs);

  return (
    <>
      <div className="web-pages-main-container faq-main-container">
        {/* Hero */}
        <div className="hero-main">
          {/* Hero Image */}
          <img
            className="about-hero-img"
            src="https://www.apcey.com/images/hotel/hero/faq_hero.jpg"
            alt=""
          ></img>
        </div>

        <p className="faq-title">Frequently Asked Questions</p>

        <div className="faq-item-container">
          <div className="faq-col">
            {firstHalf.map((faq, index) => (
              <div
                className="faq-item"
                onClick={() => {
                  var newFaqs = firstHalf.map((item) =>
                    item.question === faq.question
                      ? { ...faq, state: !faq.state }
                      : item
                  );
                  setFirstHalf(newFaqs);
                }}
              >
                <div className="faq-item-question-continer">
                  <img
                    src="https://www.apcey.com/icons/question.svg"
                    alt=""
                    className="faq-item-question-icon"
                  />
                  <p className="faq-item-question-text">{faq.question}</p>
                </div>
                {faq.state && (
                  <p className="faq-item-answer-text">{faq.answer}</p>
                )}
              </div>
            ))}
          </div>

          <div className="faq-col">
            {secondHalf.map((faq, index) => (
              <div
                className="faq-item"
                onClick={() => {
                  var newFaqs = secondHalf.map((item) =>
                    item.question === faq.question
                      ? { ...faq, state: !faq.state }
                      : item
                  );
                  setSecondHalf(newFaqs);
                }}
              >
                <div className="faq-item-question-continer">
                  <img
                    src="https://www.apcey.com/icons/question.svg"
                    alt=""
                    className="faq-item-question-icon"
                  />
                  <p className="faq-item-question-text">{faq.question}</p>
                </div>
                {faq.state && (
                  <p className="faq-item-answer-text">{faq.answer}</p>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
