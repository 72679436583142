import { useStateContext } from "../../StateContext";
import SimpleAlert from "./SimpleAlert";
import ConfirmAlert from "./ConfirmAlert";
import LoadingAlert from "./LoadingAlert";

export default function AlertsUI() {
  const { isSimpleAlert, isConfirmAlert, isLoading } = useStateContext();

  return (
    <div>
      {isSimpleAlert && <SimpleAlert />}

      {isConfirmAlert && <ConfirmAlert />}

      {isLoading && <LoadingAlert />}
    </div>
  );
}
