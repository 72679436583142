import axios from "axios";

export const postReq = async (methodName, postData, isSave, isSort) => {
  const BASE_URL = "https://www.apcey.com/api/hotel/";

  const SEND_NOTIFICATION_URL = "notification/send_notitication.php";
  const GET_ROOM_CATEGORIES_URL = "room_categories/get/get.php";
  const BOOK_ADD_MULTIPLE_URL = "book/add/multibook.php";
  const SEND_MESSAGE_URL = "message/add/add.php";

  var PATH = "";

  if (methodName === "sendNotification") {
    PATH = SEND_NOTIFICATION_URL;
  } else if (methodName === "roomCategoriesData") {
    PATH = GET_ROOM_CATEGORIES_URL;
  } else if (methodName === "addBook") {
    PATH = BOOK_ADD_MULTIPLE_URL;
  } else if (methodName === "sendMessage") {
    PATH = SEND_MESSAGE_URL;
  }

  try {
    const response = await axios.post(BASE_URL + PATH, postData);
    if (response?.data?.status === "success" && isSave) {
      if (isSort) {
        localStorage.setItem(
          methodName,
          JSON.stringify(
            response?.data?.records.sort((a, b) => (a.sort > b.sort ? 1 : -1))
          )
        );
      } else {
        localStorage.setItem(
          methodName,
          JSON.stringify(response?.data?.records)
        );
      }
    }
    return response?.data;
  } catch (err) {
    // console.log(
    //   " API Call Error ==> Method Name: " + methodName + " Error ==> " + err
    // );
    return { status: "fail", message: err };
  }
};
