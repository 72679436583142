// StateContext.js

import { createContext, useContext, useState } from "react";

const StateContext = createContext();

export const StateProvider = ({ children }) => {
  const [itemCount, setItemCount] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isSimpleAlert, setIsSimpleAlert] = useState(false);
  const [simpleAlertTitle, setSimpleAlertTitle] = useState("");
  const [simpleAlertMsg, setSimpleAlertMsg] = useState("");
  const [simpleAlertPositiveBtnText, setSimpleAlertPositiveBtnText] =
    useState("");
  const [simpleAlertType, setSimpleAlertType] = useState(1);

  const [isConfirmAlert, setIsConfirmAlert] = useState(false);
  const [confirmAlertTitle, setConfirmAlertTitle] = useState("");
  const [confirmAlertMsg, setConfirmAlertMsg] = useState("");
  const [confirmAlertPositiveBtn, setConfirmAlertPositiveBtn] = useState("");
  const [confirmAlertNegativeBtn, setConfirmAlertNegativeBtn] = useState("");
  const [confirmAlertType, setConfirmAlertType] = useState(1);
  const [confirmEventName, setConfirmEventName] = useState("");
  const [confirmAlertEvent, setConfirmAlertEvent] = useState();

  const [userData, setUserData] = useState({});

  const [alertCount, setAlertCount] = useState(0);

  const setSimpleAlert = (title, msg, positiveBtn, type) => {
    setSimpleAlertTitle(title);
    setSimpleAlertMsg(msg);
    setSimpleAlertPositiveBtnText(positiveBtn);
    setSimpleAlertType(type);
    setIsSimpleAlert(true);
  };

  const setConfirmAlert = (
    title,
    msg,
    positiveBtn,
    negativeBtn,
    type,
    confirmEventName
  ) => {
    setConfirmAlertTitle(title);
    setConfirmAlertMsg(msg);
    setConfirmAlertPositiveBtn(positiveBtn);
    setConfirmAlertNegativeBtn(negativeBtn);
    setConfirmAlertType(type);
    setConfirmEventName(confirmEventName);
    setIsConfirmAlert(true);
  };

  return (
    <StateContext.Provider
      value={{
        setConfirmAlert,
        setSimpleAlert,
        isLoading,
        setIsLoading,
        isSimpleAlert,
        setIsSimpleAlert,
        simpleAlertTitle,
        setSimpleAlertTitle,
        simpleAlertMsg,
        setSimpleAlertMsg,
        simpleAlertPositiveBtnText,
        setSimpleAlertPositiveBtnText,
        simpleAlertType,
        setSimpleAlertType,
        isConfirmAlert,
        setIsConfirmAlert,
        confirmAlertTitle,
        setConfirmAlertTitle,
        confirmAlertMsg,
        setConfirmAlertMsg,
        confirmAlertPositiveBtn,
        setConfirmAlertPositiveBtn,
        confirmAlertNegativeBtn,
        setConfirmAlertNegativeBtn,
        confirmAlertType,
        setConfirmAlertType,
        confirmEventName,
        setConfirmEventName,
        confirmAlertEvent,
        setConfirmAlertEvent,
        itemCount,
        setItemCount,
        alertCount,
        setAlertCount,
        userData,
        setUserData,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => {
  return useContext(StateContext);
};
