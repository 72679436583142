import { useRef, useEffect } from "react";
import "./Alerts.css";
import { useStateContext } from "../../StateContext";

export default function SimpleAlert() {
  const buttonRef = useRef(null);

  const {
    setIsSimpleAlert,
    simpleAlertTitle,
    simpleAlertMsg,
    simpleAlertPositiveBtnText,
    simpleAlertType,
  } = useStateContext();

  useEffect(() => {
    if (buttonRef.current) {
      buttonRef.current.focus();
    }

    function handleKeyDown(event) {
      if (
        event.key === "Enter" ||
        event.key === "Escape" ||
        event.key === "Control" ||
        event.key === "Shift"
      ) {
        setIsSimpleAlert(false);
      }
    }

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [setIsSimpleAlert]);

  return (
    <div className="alert-main-container alert-z-forward">
      <div className="alert-dialog-container">
        <div
          className={`alert-header ${
            simpleAlertType === 1
              ? "alert-bg-green"
              : simpleAlertType === 2
              ? "alert-bg-red"
              : simpleAlertType === 3
              ? "alert-bg-yellow"
              : "alert-bg-blue"
          }`}
        >
          <img
            className="alert-img"
            src={
              simpleAlertType === 1
                ? "https://www.apcey.com/icons/success.svg"
                : simpleAlertType === 2
                ? "https://www.apcey.com/icons/error.svg"
                : simpleAlertType === 3
                ? "https://www.apcey.com/icons/warning.svg"
                : "https://www.apcey.com/icons/info.svg"
            }
            alt=""
          />

          <p className="alert-title">{simpleAlertTitle}</p>
        </div>

        <div className="alert-body">
          <pre className="alert-msg">{simpleAlertMsg}</pre>

          <button
            ref={buttonRef}
            className={`alert-btn ${
              simpleAlertType === 1
                ? "alert-bg-green"
                : simpleAlertType === 2
                ? "alert-bg-red"
                : simpleAlertType === 3
                ? "alert-bg-yellow"
                : "alert-bg-blue"
            }`}
            onClick={() => {
              setIsSimpleAlert(false);
            }}
          >
            {simpleAlertPositiveBtnText}
          </button>
        </div>
      </div>
    </div>
  );
}
